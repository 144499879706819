@charset "utf-8";


body {
    font-family: 'Yu Gothic, Serif';
}

input,
button,
select,
textarea {
    outline: none;
}

img {
    max-width: 100%;
}

a:hover,
a:focus {
    text-decoration: none;
}

a {
    color: #333;
}

.owl-theme .owl-nav {
    margin-top: 0px !important;
}

.owl-carousel .owl-nav .img-btn-left,
.owl-carousel .owl-nav .img-btn-right {
    margin: 0px !important;
    padding: 0px !important;
}

.img-btn-left,
.img-btn-right {
    margin: 0px !important;
    border-radius: 0px !important;

}

.fl-left {
    float: left !important;
}

.fl-right {
    float: right !important;
}



/* css main slider */

#slider-home .img-btn-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

#slider-home .img-btn-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

#slider-home .owl-nav .fa {
    background-color: rgba(51, 51, 51, 0.6);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

.second-nav {
    display: none;
}

.hc-nav-trigger.hc-nav-1 {
    display: none !important;
}

.container {
    width: 1224px
}

header:not(.header-after-login-for-std) {
    padding: 24px 34px;
}

header .main-logo {
    width: 234px;
    display: inline-block;
    float: left;
}

header .menu-header {
    width: calc(100% - 272px);
    display: inline-block;
    float: right;
    padding-left: 10px
}

header {
    display: table;
    width: 100%;
    clear: both;
    box-shadow: 0px 1px 0px #00000014;

}

header .menu-header ul li {
    display: inline-block;
    float: left;
    text-align: left;
}

header .menu-header ul li a {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-decoration: none;
    display: inline-block;
    padding: 0 38px;
    position: relative;
}

header .menu-header ul li a:hover {
    color: #FCB900;
}

header .menu-header ul li a:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 12px;
    background: #00123333;
    left: 0;
    top: 6px;
}

header .menu-header ul li:first-child a:before {
    content: '';
    display: none;
}

header .menu-header ul li .register-login:before {
    content: none !important;
}

.menu-header {
    text-align: right;

}

.menu-header .item-menu-header:hover {
    cursor: pointer;
    color: #FCB900;
}

header .menu-header ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    padding: 0;
    margin: 0;
    -webkit-justify-content: flex-end;
    -webkit-align-items: flex-center;
}

header .menu-header ul li .register-login {
    height: 49px;
    width: 140px;
    border: 2px solid red;
    border-radius: 24px;
    padding: 0;
    text-align: center;
    line-height: 49px;
    color: red;
}

header .menu-header ul li .register-login:hover {
    background: red;
    color: #fff;
}

header .menu-header ul li .register-login-2 {
    background: #1A2C44;
    color: #fff;
    margin-left: 12px;
    border: 2px solid #1A2C44;

}

header .menu-header ul li .register-login-2:hover {
    background: #FCB900;
    color: #1A2C44;
    border: 1px solid #FCB900
}

.Banner-Home .img-banner {
    /* width: calc(100% - 260px); */
    width: 100%;
    display: inline-block;
    position: relative;
    z-index: 11
}

.Banner-Home .img-banner img {
    width: 100%;
    /* height: 723px;
    object-fit: cover;
    object-position: 100% 0%; */
}

.Banner-Home {
    position: relative;
}

/* .Banner-Home:after {
    content: '';
    position: absolute;
    width: 948px;
    height: 1050px;
    background: #F5F5F5;
    right: 0;
    top: 175px;
    z-index: 0;
} */

.Banner-Home .content-banner {
    position: absolute;
    right: 0;
    background: #1A2C44 0% 0% no-repeat padding-box;
    opacity: 0.9;
    height: 280px;
    width: 739px;
    z-index: 11;
    bottom: -100px;
    align-items: center;
    display: flex;
    padding: 50px;
}

.Banner-Home .content-banner .title-1 {
    font: normal normal bold 28px/40px Yu Gothic;
    text-align: center;
    color: #FCB900;
}

.Banner-Home .content-banner .desc {
    text-align: left;
    font: normal normal 500 16.5px/25px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0px;
    margin-top: 25px;
}

.Banner-Home .header-tool-certificate-alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fcdede;
    padding: 3px 10px;
    border: 1px solid #f9bcbc;
    margin-bottom: 5px;
    gap: 5px;
}

.Banner-Home .header-tool-certificate-alert .close-icon {
    color: #bdbdbd;
}

.Banner-Home .header-tool-certificate-alert span {
    font-size: 14px;
    color: #d30000;
}

.Banner-Home .carousel-information .carousel-information-title {
    font-family: 'Arial Black', 'Arial', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 22px;
    color: #1A2C44;
    padding-left: 100px;
    padding-right: 120px;
    display: flex;
    align-items: center;
}

.Banner-Home .carousel-information .carousel-information-title span {
    min-width: 150px;
}

.Banner-Home .carousel-information .view-lst-information-hyperlink-title {
    font-size: 12px;
    color: #7F7F7F;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
    display: none;
}

@media (max-width: 1600px) {
    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 90px;
        padding-right: 150px;
    }
}

@media (max-width: 1460px) {
    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 80px;
        padding-right: 120px;
    }
}

@media (max-width: 1280px) {
    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (max-width: 768px) {
    .Banner-Home .carousel-information {
        flex-direction: column;
        padding-top: 10px;
    }

    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
        justify-content: space-between;
        width: 100%;
    }

    .Banner-Home .carousel-information .carousel-information-title span {
        min-width: none;
    }

    .Banner-Home .carousel-information .view-lst-information-hyperlink-title {
        display: block;
    }

    .Banner-Home .carousel-information .view-lst-information-hyperlink {
        display: none;
    }
}

/* @media (max-width: 600px) {
    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 30px;
        padding-right: 10px;
    }
}

@media (max-width: 480px) {
    .Banner-Home .carousel-information .carousel-information-title {
        padding-left: 10px;
        padding-right: 0px;
    }
} */


.Banner-Home .carousel-information .view-lst-information-hyperlink {
    font-size: 14px;
    color: #1A2C44;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
}

.Banner-Home .carousel-information .view-lst-information-hyperlink .hyperlink:hover {
    font-weight: 500;
    text-decoration: underline;
}

.title-primary {
    font: normal normal bold 40px/65px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 0;
}

.title-secondary {
    font: normal normal bold 30px/30px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 0;
}

.top-content {
    padding-top: 108px;
    position: relative;
    z-index: 11
}

.top-content .content-top-content .theme-item, .title-sample-video {
    font: normal normal bold 18px/30px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    text-decoration: inherit;
    margin-bottom: 30px;
}

.top-content .content-top-content .theme-item:hover {
    color: #FCB900;
}

.top-content .content-top-content {
    margin-top: 35px
}

/*.course-home{
	padding-left: 348px
}*/
.course-home .item .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    margin-top: 24px;
}

.course-home .item .title-2 a {
    color: #1A2C44;
    text-decoration: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
    -webkit-line-clamp: 1;
    height: 26px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    letter-spacing: 0px;
}

.other-slider-new .item .title-2 a {
    margin-top: 20px;
}

.course-home .item .title-2 a:hover {
    color: #FCB900;
}

.course-home .item .title-category a {
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 14px;
    background: #0077B6;
    height: 30px;
    padding: 0 12px;
    text-decoration: initial;
    border: 1px solid #0077B6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.course-home .item .title-category a:hover {
    /* background: #fff;
    color: #0077B6; */
}

.course-home .item .clock-video ul li img {
    width: auto;
    display: inline-block;
    float: left;
    margin-right: 8px;
    margin-top: 4px;
}

.course-home .item .clock-video ul li.v img {
    width: 30px;
    object-fit: contain;
    margin-top: 0px;
    margin-right: 0px;
}

.course-home .item .clock-video ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.course-home .item .clock-video ul li {
    /* margin-right: 22px; */
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

/* .top-content .content-top-content .col-md-4:nth-child(3n + 1) .list-item {
    border-left: 2px solid #FCB900;
    padding-left: 30px;
} */

.top-content .content-top-content  {
    border-left: 2px solid #FCB900;
    padding-left: 30px;
}

.course-home {
    padding-top: 192px;
}

.course-home .item .desc {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
    -webkit-line-clamp: 5;
    height: 140px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    border-top: 1px solid #DADDE2;
    padding-top: 10px;
    margin-top: 10px;
}

.course-home .item .read-more {
    font: normal normal bold 16px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

    display: inline-block;

}

.course-home .slider-course {
    margin-top: 37px;
}

.course-home .item .img img {
    /* height: 214px; */
    object-fit: fill;
    width: 100%;
    aspect-ratio: 16/9;
}

.course-home .item .img {
    overflow: hidden;
}

img {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.course-home .item:hover .img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.course-home .slider-course .img-btn-left {
    font-size: 0;
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */
    width: 56px;
    height: 56px;
    position: relative;
}

.course-home .slider-course .img-btn-left.swiper-button-swiper-button-disabled {
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */
}

.course-home .slider-course .btn-right-slide.swiper-button-swiper-button-disabled {
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */
}

.course-home .slider-course .btn-left-slide {
    position: absolute;
    top: -107px;
    right: 72px;
    z-index: 111;
}

.course-home .slider-course .btn-right-slide.swiper-button-swiper-button-disabled .img-btn-left:after {
    content: url('/public/images/arrow-hover.svg');
}

.course-home .slider-course .btn-right-slide.diswiper-button-swiper-button-disabled .img-btn-left:after {
    content: url('/public/images/arrow-hover.svg');
}

.course-home .slider-course .img-btn-left:hover {
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */
    filter: FlipH;
}

.course-home .slider-course .img-btn-left:after {
    content: url('/public/images/arrow.svg');

}
.course-home .slider-course .img-btn-left:hover:after {
    content: url('/public/images/arrow-hover.svg');
}

/* .other-slider-new .slider-course .img-btn-left:hover:after {
    content: url('/public/images/arrow-hover.svg') !important;
} */

.course-home .slider-course .btn-right-slide {
    font-size: 0;
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */
    filter: FlipH;
    margin-left: 16px !important;

}

.course-home .slider-course .btn-right-slide:after {
    content: url('/public/images/arrow.svg');
    /* IE 6/7/8 */
}

.course-home .slider-course .btn-right-slide:hover:after {
    content: url('/public/images/arrow-hover.svg');
    font-size: 0;
}

.releted-lecture .slider-course .img-btn-left {
    transform: scaleX(-1);
}

.releted-lecture .slider-course .img-btn-left:after {
    content: url('/public/images/icon3.svg') !important;
}

.releted-lecture .slider-course .btn-right-slide:after {
    content: url('/public/images/icon3.svg') !important;
    transform: scaleX(1);
}

.releted-lecture .slider-course .btn-right-slide {
    transform: scaleX(1) !important;
}

.releted-lecture .slider-course .btn-right-slide:hover:after {
    content: url('/public/images/arrow-hover.svg') !important;
    font-size: 0;
}

.releted-lecture .slider-course .img-btn-left:hover:after {
    content: url('/public/images/arrow-hover.svg') !important;
    font-size: 0;
}


.course-home .slider-course .btn-right-slide:hover {
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */

    filter: FlipH;
}

.course-home .slider-course .btn-right-slide {
    position: absolute;
    top: -107px;
    right: 0px;
    z-index: 111;

}

.course-home .slider-course .owl-nav {
    position: absolute;
    top: -111px;
    right: 400px;
    display: flex;
}

.course-home .item .read-more:hover {
    text-decoration: underline;
    color: #FCB900;
}

.new-home {
    padding-left: 0;
    padding-top: 151px;
}

.slider-course .owl-dots {
    padding-right: 390px;
    display: inline-block;
    width: 100%;
    margin-top: 47px;
}

.slider-course .owl-dots .owl-dot {
    height: 4px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;

    width: calc(100% / 3);
    display: inline-block;
    float: left;
}

.slider-course .owl-dots .owl-dot.active {
    background: #1A2C44;
}

.item-readMore {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;

}

.item-viewMore {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 700;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.item-viewMore:hover {
    text-decoration: underline;
    /* color: #FCB900; */
}

.item-readMore a {
    display: flex;
    align-items: center;
    font: normal normal 500 14px/24px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

}

.item-readMore a img {
    width: auto !important;
    margin-left: 15px
}

.item-readMore a:hover {
    color: #FCB900 !important;
    text-decoration: underline;
}

.new-home .row {
    margin-left: -21px;
    margin-right: -21px;
}

.new-home .row .col-md-4 {
    padding-left: 21px;
    padding-right: 21px
}

.course-home .item .clock-video ul li.red {
    color: #F21313;
}

.course-home .item .clock-video ul li:last-child {
    margin-right: 0
}

.new-home .item {
    margin-bottom: 80px
}

.new-home .title-primary {
    margin-bottom: 39px
}

.new-home .item-readMore {
    margin-top: 7px;
}

.Price-home {
    position: relative;
}

.Price-home:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 450px;
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    z-index: 0
}

.Price-home .container {
    position: relative;
    z-index: 1;
    top: -26px;
}

.Price-home {
    margin-top: 198px;
    padding-top: 136px
}

.Price-home .description {
    text-align: center;
    font: normal normal bold 20px/30px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    max-width: 1016px;
    margin: auto;
}

.Price-home .description p {
    margin-bottom: 30px;
}

.Price-home .item {
    border: 2px solid var(--unnamed-color-f5f5f5);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #F5F5F5;
    border-radius: 24px;
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    min-height: 430px;
}

.Price-home .item.item-last {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.Price-home .owl-stage {
    display: flex;
}

.Price-home .item.item-last .item-readMore {
    margin: 0;
}

.Price-home .item h3 {
    background: #363946 0% 0% no-repeat padding-box;
    border-radius: 24px 24px 0px 0px;
    opacity: 1;
    font: normal normal bold 24px/36px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;

    text-align: center;
    padding: 14px 20px 9px 20px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.Price-home .item .nav-item {
    padding: 5px 26px 45px 26px;
}

.Price-home .item .nav-item .price {
    font: normal normal bold 40px/65px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;

    margin: 0;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.Price-home .slider-price {
    margin-top: 37px;
    position: relative;
}

.Price-home .item .nav-item .desc-1 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

    margin-bottom: 24px;
}

.Price-home .item .nav-item .desc-1 span {
    font: normal normal bold 16px/26px Yu Gothic;
    color: #FCB900;
}

.Price-home .item .nav-item .desc-2 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.Price-home .item .nav-item .read-more {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

    margin-top: 38px;
    display: inline-block;

    text-decoration: inherit;
    display: inline-block;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.Price-home .item .nav-item .read-more span {
    border-bottom: 1px solid;
}

.Price-home .item .nav-item .read-more img {
    width: auto !important;
    height: auto;
    display: inherit;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.Price-home .item:hover .nav-item .read-more img {
    visibility: visible;
    opacity: 1;
    margin-left: 3px
}

a {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.Price-home .item:hover .nav-item .read-more {
    color: #FCB900;
}

.Price-home .item:hover .nav-item .price {
    color: #FCB900;
}

.Price-home .item:hover {
    border: 2px solid #FCB900
}

.Price-home .item:hover h3 {
    background: #FCB900
}




.course-home .item.item-readMore {
    display: flex;

    margin-top: 248px;
    min-height: 533px;
    margin: 0;
    justify-items: center;
    align-items: center;
}

.course-home .content-course-home {
    position: relative;
    min-height: 707px;
}

.course-home .content-course-home .content-absolute {
    position: absolute;
    top: 0;
    left: 0;

    width: 1600px;
}

.course-home {
    overflow: hidden;
}

.section-fqa .item-readMore {
    justify-content: start;
}


.section-fqa .acc-head {


    position: relative;
    cursor: pointer;
    padding: 23px 14px;
    border-bottom: 1px dashed #DADDE2;
    padding-right: 44px;
}

.section-fqa .acc:first-child .acc-head {
    border-top: 1px dashed #DADDE2;

}


.section-fqa .acc-head::before {
    content: url('/public/images/icon6.png');
    position: absolute;
    position: absolute;
    right: 0;
    top: 19px;
}

.section-fqa .acc-head.active::before {
    content: url('/public/images/icon5.png');
    position: absolute;
    position: absolute;
    right: 0;
    top: 19px;
}

.section-fqa .acc-content {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 18px 28px 21px 14px;
    display: none;
}


.section-fqa .section-fqa-right .acc .acc-head .title-2 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
    padding: 0;
}

.section-fqa .section-fqa-right .acc .acc-head .title-2 .faq-question {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    font: normal normal bold 16px/26px Yu Gothic;
    margin-right: 21px;
    line-height: 32px;
}

.section-fqa .section-fqa-right .acc .title-3 {
    margin: 0;
    position: relative;
    padding-left: 46px;
}

.section-fqa .section-fqa-right .acc .title-3 .faq-answer {
    background: #FCB900 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 32px;
    height: 32px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

.section-fqa {
    padding-top: 172px;
    padding-bottom: 148px
}

.section-fqa .section-fqa-right {
    padding-left: 50px
}

.section-fqa .item-readMore {
    align-items: initial;
    height: auto;
    margin-top: 27px;
}

#Footer .top-footer {
    width: 648px
}

#Footer {
    background: #363946 0% 0% no-repeat padding-box;
    padding-top: 44px;

}

#Footer .item-footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#Footer .item-footer ul li a {
    font: normal normal 500 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-decoration: initial;
}

#Footer .item-footer ul li a:hover {
    color: #FCB900;
}

#Footer .item-footer ul li {
    padding-bottom: 3px
}

#Footer .item-footer {
    margin-top: 12px;
}

.copyright {
    padding: 24px 0;
    border-top: 1px solid #fff;
    opacity: 0.4;
    margin-top: 44px;
}

.copyright p {
    font: normal normal 500 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;

}

.item-readMore a:hover {
    padding-left: 10px;
}

.hc-offcanvas-nav .link-login {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hc-offcanvas-nav .link-login li:nth-child(2) {
    width: calc((100% - 15px) / 2);
    margin-right: 15px;
}

.hc-offcanvas-nav .link-login li:nth-child(3) {
    width: calc((100% - 15px) / 2);

}

.hc-offcanvas-nav .link-login li:last-child {
    margin-right: 0
}

.hc-offcanvas-nav .link-login li a {
    height: 34px;
    border: 2px solid #1A2C44;
    border-radius: 24px;
    padding: 0;
    text-align: center;
    line-height: 34px;
}

.hc-offcanvas-nav .link-login {
    padding: 16px;
    padding-bottom: 5px;
    padding-top: 0;
}

.link-login-button {
    margin-top: 50px;
}

.link-register-button {
    color: red !important;
    border-color: red !important;
}

.hc-offcanvas-nav .link-login .nav-close {
    margin-top: 0;
}

.hc-offcanvas-nav .link-login .nav-close a {
    border: 0;
    height: auto;
    line-height: 50px;
}

.hc-offcanvas-nav .link-login li.li-login a {
    background: #1A2C44;
    color: #fff;
}

.hc-offcanvas-nav .second-nav li a {
    color: #1A2C44;
    font-weight: bold;
}

.hc-offcanvas-nav .login-2 .login-admin>a {
    display: inline-block;
    width: 100%;
}

.hc-offcanvas-nav .login-2 .login-admin .icon-1 {
    width: 42px;
    display: inline-block;
    float: left;
}

.hc-offcanvas-nav .login-2 .login-admin .nav-icon-1 {
    width: calc(100% - 42px);
    display: inline-block;
    float: right;
    padding-left: 5px;
}

.hc-offcanvas-nav .login-2 li a {
    padding: 8px 17px;
    border-bottom: 0;
}

.hc-offcanvas-nav .login-2 {
    font-weight: bold;
}

.hc-offcanvas-nav .login-box {
    background: #F5F6F8;
    font-weight: bold;
    padding: 20px 0;
}

.hc-offcanvas-nav .login-box li a {
    border-bottom: 0;
    padding: 3px 17px;
}

.hc-offcanvas-nav .login-box li.red a {
    color: #F21313;
}

.hc-offcanvas-nav .login-box li.btn a {
    display: inline-block;
    background: #FCB900;
    font-weight: bold;
    padding: 7px 20px;
    border-radius: 20px;
}

.hc-offcanvas-nav .login-2 li.nav-close a {
    padding: 0
}

header.header-after-login .menu-header ul li a {
    padding: 0 20px;
}

header.header-after-login .menu-header ul li.li-date a {
    font: normal normal bold 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    padding: 0 12px;
}

header.header-after-login .menu-header ul li.li-date a:before {
    display: none
}

header.header-after-login .menu-header ul li.li-ticket a {
    font: normal normal bold 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    padding: 0 12px;
}

header.header-after-login .menu-header ul li.li-ticket a:before {
    display: none
}

header.header-after-login .menu-header ul li.li-ticket a span {
    color: #F21313;
}

header.header-after-login .menu-header ul li.btn-t a, header.header-after-login .menu-header-for-std li.btn-t a {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal bold 14px/24px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    padding: 0 16px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #FCB900;
}

header.header-after-login .menu-header ul li.btn-t a:hover, header.header-after-login .menu-header-for-std li.btn-t a:hover {
    color: #1A2C44;
    background: #fff
}

header.header-after-login .menu-header ul li.btn-t a:before, header.header-after-login .menu-header-for-std li.btn-t a::before {
    display: none;
}

/* header.header-after-login .menu-header ul li.btn-t a img, header.header-after-login .menu-header-for-std li.btn-t a img {
    float: right;
    margin-left: 5px;
    margin-top: 7px;
} */

header.header-after-login .menu-header ul li.li-bell a:before {
    display: none
}

header.header-after-login .menu-header ul li.li-last a {
    display: flex;
    padding: 0;
}

header.header-after-login .menu-header ul li.li-last a .icon {
    width: 44px;
    margin-right: 16px;
}

header.header-after-login .menu-header ul li.li-last a .nav-icon {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    line-height: 19px;

}

header.header-after-login .menu-header ul li.li-last a .nav-icon b {
    font: normal normal bold 16px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

header.header-after-login .menu-header ul li.li-last a:before {
    display: none;
}

header.header-after-login .menu-header ul li.li-last a i {
    margin-left: 5px
}

.bres-cus ul li {
    font: normal normal 500 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.bres-cus ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none
}

.bres-cus ul li.dash {
    font-family: arial;
    color: #DADDE2;
    padding: 0 16px;
}

.bres-cus {
    padding: 19px 0;
}

.main-signin {
    position: relative;
}

.main-signin:after {
    background-image: url('/public/images/logo-bg.png');
    display: inline-block;
    width: 732px;
    height: 598px;
    content: "";
    background-size: 100% 100%;
    position: absolute;
    bottom: -73px;
    right: 0;

}

.main-signin .content-signin .content-form {
    width: 496px
}

.main-signin .content-signin {
    padding-top: 34px;
}

.main-signin .content-signin .item label {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    display: inline-block;
    width: 100%;
}

.main-signin .content-signin .item input[type=text] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    margin-top: 9px;
}

.main-signin .content-signin .item input[type=password] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    margin-top: 9px;
}

.main-signin .content-signin form {
    margin-top: 26px;
}

.main-signin .content-signin .item-1 {
    margin-bottom: 31px
}

.main-signin .content-signin .item-checkbox {
    margin-top: 14px;
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    display: flex;
    align-items: center;
}

.main-signin .content-signin .item-checkbox input[type=checkbox] {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 12px;
}

.main-signin .content-signin [type=submit] {
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 28px;
    height: 56px;
    width: 100%;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    border: 1px solid #DADDE2;
    margin-top: 32px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-signin .content-signin [type=submit]:hover {
    background: #FCB900;
    color: #363946;
}

.main-signin .content-signin .note {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-top: 32px;
    margin-bottom: 10px;
}

.main-signin .content-signin .note-2 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
}

.main-signin .content-signin .note-2 i {
    font-size: 20px;
    margin-right: 12px;
}

.main-signin .content-signin .note-2 a {
    text-decoration: underline;
}

.main-signin {
    padding-bottom: 147px;
}

.main-signin .content-signin .item .input {
    position: relative;

}

.main-signin .content-signin .item .input button {
    position: absolute;
    right: 15px;
    top: 20px;
    background: none;
    padding: 0;
    border: 0;
}

.main-signin .content-signin .item label span {
    color: #F21313;
}

.main-signin .content-signin form .change-role-tab {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    margin-bottom: 24px;
    padding: 8px;
    gap: 10px;
}

.main-signin .content-signin form .change-role-tab .active {
    background-color: #FFFFFF;
    border-radius: 4px;
    height: 100%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.main-signin .content-signin form .change-role-tab .active > span {
    font-weight: 600;
}


.main-Forgot-Password .content-form .note-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-bottom: 0
}

.main-Forgot-Password .content-form form {
    margin-top: 22px;
    position: relative;
}

.main-Forgot-Password .content-form form input[type=text] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    padding-left: 15px;
}

.main-Forgot-Password .content-form form button {
    background: #DADDE2 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 0px 4px 4px 0px;
    height: 48px;
    width: 71px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

}

.main-Forgot-Password .linklink {
    margin-top: 49px;
}

.main-Forgot-Password .linklink a {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    text-decoration: underline;
}

.main-Forgot-Password .linklink a:hover {
    color: #FCB900;
}

.main-Forgot-Password {
    padding-bottom: 260px
}

.main-Forgot-Password .content-form form button {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-Forgot-Password .content-form form button:hover {
    background: #FCB900;
}

.main-ChangPassword .content-signin form {
    margin-top: 12px;
}

.main-ChangPassword .content-signin .item {
    margin-bottom: 24px
}

.main-ChangPassword .content-signin .item input[type=password] {
    margin-top: 7px;
}

.main-ChangPassword .content-signin [type=submit] {
    margin-top: 11px;
    background: #FCB900;
    color: #363946;
}

.main-ChangPassword {
    padding-bottom: 112px;
}

.main-account-trgisstration-1 .step-step ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    background: #DADDE266 0% 0% no-repeat padding-box;
    border-radius: 4px;
    height: 59px;
    line-height: 59px;
    margin: 0;
    padding: 0;

}

.main-account-trgisstration-1 .step-step {
    width: 808px;
    margin: auto;
    margin-top: 29px;
    overflow: hidden;

}

.main-account-trgisstration-1 .step-step ul li {
    width: calc(100% / 3);
    position: relative;
    font: normal normal bold 18px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    line-height: 59px;
}

.main-account-trgisstration-1 .step-step ul .arrow-right.active:after {
    content: url('/public/images/ar3.png');
    right: 0;
    top: 0;
    position: absolute;

}

.main-account-trgisstration-1 .step-step ul .arrow-right.active-done img {
    margin-right: 15px
}

.main-account-trgisstration-1 .step-step ul .arrow-right.active-done:after {
    content: url('/public/images/ar4.png');
    right: 0;
    top: 0;
    position: absolute;
}

.main-account-trgisstration-1 .step-step ul .arrow-right.active-done-step-next:after {
    content: url('/public/images/ar6.png');
    right: 0;
    top: -1px;
    position: absolute;
}

.main-account-trgisstration-1 .step-step ul .arrow-right.active-done {
    background: #363946;
    color: #fff
}

.main-account-trgisstration-1 .step-step ul .arrow-right.active-done-step-next {
    background: #363946;
    color: #fff
}

.main-account-trgisstration-1 .title-primary {
    text-align: center;
}

.main-account-trgisstration-1 .step-step ul li.arrow:after {
    content: url('/public/images/ar2.png');
    right: 0;
    top: 0;
    position: absolute;
}

.main-account-trgisstration-1 .step-step ul {
    text-align: center;
}


.main-account-trgisstration-1 .step-step ul li.active {
    background: #fcb900;
}

.main-account-trgisstration-1 .box-form-account {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    padding: 48px 0;
    margin-top: 28px;
}

.main-account-trgisstration-1 .box-form-account .content-content {
    width: 600px;
    margin: auto;
}

.main-account-trgisstration-1 .form-account {
    margin-top: 33px;
}

.main-account-trgisstration-1 .form-account .title-2 {
    font: normal normal bold 24px/39px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-align: center;
}

.main-account-trgisstration-1 .content-content p.input {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    opacity: 1;
}

.main-account-trgisstration-1 .content-content p.input input[type=checkbox] {
    width: 24px;
    height: 24px;
    display: inline-block;
    float: left;
    margin-right: 16px;
    border: 1px solid #DADDE2;
}

.main-account-trgisstration-1 .content-content p.input span {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #10A9FB;
    text-decoration: underline;
    cursor: pointer;
}

.main-account-trgisstration-1 .content-content .link-button button {
    width: calc((100% - 24px) / 2);
    display: inline-block;
    float: left;
    margin-right: 24px;
    border: 1px solid #0077B6;
    height: 60px;
    background: #fff;
    border-radius: 30px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;

}

.main-account-trgisstration-1 .content-content .link-button {
    display: inline-block;
    width: 100%;
    margin-top: 26px;
}

.main-account-trgisstration-1 .content-content .link-button button:last-child {
    margin-right: 0;
}

.main-account-trgisstration-1 .content-content .link-button button.btn-2 {
    border: 1px solid #FCB900;
    color: #FCB900;
}

.main-account-trgisstration-1 .content-content .link-button button:hover {
    background: #0077B6;
    color: #fff
}

.main-account-trgisstration-1 .content-content .link-button button.btn-2:hover {
    background: #FCB900;
    color: #fff
}

.main-account-trgisstration-1 {
    padding-bottom: 138px
}

.main-account-trgisstration-2 .box-form-account {
    border: 1px solid var(--unnamed-color-dadde2);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 16px;
}

.main-account-trgisstration-2 .box-form-account .content-content {
    width: 100%;

}

.main-account-trgisstration-2 .box-form-account {
    padding: 64px 104px;
}

.main-account-trgisstration-2 .box-form-account .content-content {
    width: 100%;
}

.main-account-trgisstration-2 .box-form-account .item {
    display: flex;
    margin-bottom: 30px;
}

.main-account-trgisstration-2 .box-form-account .item .item-left {
    width: 286px;
    padding-right: 15px
}

.main-account-trgisstration-2 .box-form-account .item .item-left label {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-top: 10px;
    display: inline-block;
}

.main-account-trgisstration-2 .box-form-account .item .item-left label span {
    color: #F21313;
}

.main-account-trgisstration-2 .box-form-account .item .item-right {
    width: calc(100% - 286px);
}

.main-account-trgisstration-2 .box-form-account .item .item-right input[type=text] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-account-trgisstration-2 .box-form-account .item .item-right input[type=password] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-account-trgisstration-2 .box-form-account .item .item-right .note-1 {
    font: normal normal 500 14px/24px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
    margin-top: 10px;
}

.main-account-trgisstration-2 .box-form-account .note-2 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
    text-align: center;
    margin-top: 44px;
}

.prev-next .prev {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    opacity: 1;
    text-decoration: inherit;
    margin-top: 17px;
    display: inline-block;
}

.prev-next .btn-prev {
    display: inline-block;
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 194px;
    height: 56px;
    text-align: center;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    opacity: 1;
    text-decoration: inherit;
    line-height: 56px;
    border: 0px;
}

.prev-next .btn-prev:hover {
    background: #FCB900;
}

.prev-next .prev:hover {
    color: #FCB900;
}

.prev-next .next {
    text-align: right;
 
}

.prev-next .next a {
    display: inline-block;
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 194px;
    height: 56px;
    text-align: center;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    opacity: 1;
    text-decoration: inherit;
    line-height: 56px;
}

.prev-next .next button {
    display: inline-block;
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 194px;
    height: 56px;
    text-align: center;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    opacity: 1;
    text-decoration: inherit;
    line-height: 56px;
    border: 0px;
}

.prev-next .next a span {
    float: right;
}

.prev-next .next a:hover {
    background: #FCB900;
}

.prev-next .next button:hover {
    background: #FCB900;
}

.main-account-trgisstration-2 .prev-next {
    margin-top: 64px;
}

.main-account-trgisstration-2 {
    padding-bottom: 148px;
}

.main-account-trgisstration-3 .content-trgisstration-3 {
    text-align: center;
    margin-top: 60px;
}

.main-account-trgisstration-3 .content-trgisstration-3 .desc {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    line-height: 42px;
    margin-top: 15px;
}

.main-account-trgisstration-3 .content-trgisstration-3 button {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 301px;
    height: 56px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    margin-top: 21px;
}

.main-account-trgisstration-3 .content-trgisstration-3 button:hover {
    background: #363946;
    color: #fff;
}

.main-account-trgisstration-3 {
    padding-bottom: 128px;
}

.main-account-trgisstration-4 .box-form-account {
    background: #F5F6F8 0% 0% no-repeat padding-box;
    border-radius: 16px;
    border: 0;
}

.main-account-trgisstration-4 .box-form-account .item .item-left {
    width: 200px;
    display: inline-block;
    float: left;
}

.main-account-trgisstration-4 .box-form-account .item .item-right {
    width: calc(100% - 200px);
    display: inline-block;
    float: right;
}

.main-account-trgisstration-4 .box-form-account .item {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #DADDE2;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.main-account-trgisstration-4 .box-form-account .item .row {
    margin-left: -43px;
    margin-right: -43px
}

.main-account-trgisstration-4 .box-form-account .item .row .col-md-6 {
    padding-left: 43px;
    padding-right: 43px;
}

.main-account-trgisstration-4 .box-form-account .item-11 .item-right input[type=text] {
    background: #F5F3F0 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
}

.main-account-trgisstration-4 .box-form-account .item-11 .item-right input[type=text]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-account-trgisstration-4 .box-form-account .item-11 .item-right input[type=text]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-account-trgisstration-4 .box-form-account .item-11 .item-right input[type=text]::-ms-input-placeholder {
    /* Microsoft Edge */
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-account-trgisstration-4 .box-form-account .item .item-right select {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
}

.main-account-trgisstration-4 .box-form-account .item .item-right input[type=date] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    padding-right: 10px;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 {
    display: inline-block;
    width: 100%;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 .input {
    width: calc(100% - 104px);
    display: inline-block;
    float: left;
    position: relative;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 button {
    position: absolute;
    right: 15px;
    top: 11px;
    background: none;
    padding: 0;
    border: 0;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .item-2 .icon {
    width: 104px;
    display: inline-block;
    float: right;
    margin-top: 10px;
    padding-left: 24px
}

.main-account-trgisstration-4 .box-form-account .item .item-right .note-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
    margin: 0;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .list-check ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .list-check ul li {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-bottom: 10px;
}

.main-account-trgisstration-4 .box-form-account .item .item-right .list-check ul li input[type=checkbox] {
    width: 24px;
    height: 24px;
    display: inline-block;
    float: left;
    margin-right: 16px;
    border: 1px solid #DADDE2;
}

.main-account-trgisstration-4 .prev-next {
    margin-top: 80px
}

.main-account-trgisstration-5 .content-trgisstration-3 {
    margin-top: 77px
}

.main-account-trgisstration-5 .content-trgisstration-3 .desc {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-account-trgisstration-5 .content-trgisstration-3 .desc {
    margin-top: 44px;
}

.main-account-trgisstration-5 .content-trgisstration-3 button {
    margin-top: 27px
}

.main-account-trgisstration-5 {
    padding-bottom: 148px;
}








.wp-slide-ls {
    position: relative;
    /* padding: 50px 0; */
}

.img-ls {
    width: 100%;
    height: 250px;
    position: relative;
    display: block;
    background-size: cover;
    background-position: center;
    background-color: #f2f2f2;
    border-top-right-radius: 20px;
}

.wp-slide-ls .swiper-container {
    overflow: visible;
}




.block-thumb-gt {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #2b2c33;
    width: 100%;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
}

.main-thumb-slide {
    border: 3px solid #f7f7f7;
    border-top: none;
    border-radius: 0 0 20px 20px;
    padding: 30px 40px 40px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: white;
}

h3.title-block-thumb-gt {
    font-size: 21px;
    line-height: 26px;
    color: #2e3657;
    font-weight: 800;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 56px;
}

p.content-block-thumb-gt {
    font-size: 17px;
    line-height: 23px;
    color: #20294b;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 115px;
}

/*.slide-ls .swiper-slide {
    width: 33% !important;
    height: 100% !important;
    transition: all 0.3s;
}*/

/*.effect, .swiper-slide-prev {
    opacity: .2;
}
*/
.swiper-slide-next {
    opacity: 1;
}

.pagination-slider-ls {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -10px;
    z-index: 2;
}



.pagination-slider-ls span {
    width: 10px;
    height: 10px;
    outline: none;
    margin: 0 4px;
    background: #ebebeb;
    opacity: 1;
}

.pagination-slider-ls span.swiper-pagination-bullet-active {
    background: #bd2781;
}

.bg-smart-phone {
    position: absolute;
    top: 38px;
    width: 190px;
    left: 49%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, .2);
    box-shadow: 0 0 50px rgba(0, 0, 0, .2);
    border-radius: 35px;
    z-index: 1;
}


.wp-slide-ls {
    position: relative;
    /* padding: 50px 0; */
}



.course-home .swiper-scrollbar {
    display: inline-block;
    height: 15px;
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    margin-top: 40px;
}

.course-home .swiper-scrollbar .swiper-scrollbar-drag {
    background: #1A2C44;
}

.Price-home .slider {
    overflow: hidden;
}

.Price-home .swiper-scrollbar-price {
    display: inline-block;
    height: 15px;
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    margin-top: 30px;
}

.Price-home .swiper-scrollbar-price .swiper-scrollbar-drag {
    background: #1A2C44;
}

.Price-home .slider-price .img-btn-left {
    font-size: 0;
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */
}

.Price-home .img-btn-left.swiper-button-swiper-button-disabled {
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */
}

.Price-home .btn-right-slide.swiper-button-swiper-button-disabled {
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */
}

.Price-home .btn-left-slide {
    position: absolute;
    top: -107px;
    right: 72px;
    z-index: 111;
}

.Price-home .btn-right-slide.swiper-button-swiper-button-disabled .img-btn-left:after {
    content: url('/public/images/arrow-hover.svg');
}

.Price-home .btn-right-slide.diswiper-button-swiper-button-disabled .img-btn-left:after {
    content: url('/public/images/arrow-hover.svg');
}

.Price-home .img-btn-left:hover {
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */

    filter: FlipH;
    width: 56px;
    height: 56px;
    position: relative;
}

.Price-home .img-btn-left:after {
    content: url('/public/images/arrow.svg');
}

.Price-home .img-btn-left:hover:after {
    content: url('/public/images/arrow-hover.svg');
}

.Price-home .btn-right-slide {
    font-size: 0;
    -moz-transform: scaleX(-1);
    /* Gecko */
    -o-transform: scaleX(-1);
    /* Opera */
    -webkit-transform: scaleX(-1);
    /* Webkit */
    transform: scaleX(-1);
    /* Standard */

    filter: FlipH;
    margin-left: 16px !important;

}

.Price-home .btn-right-slide:after {
    content: url('/public/images/arrow.svg');
    /* IE 6/7/8 */
}

.Price-home .btn-right-slide:hover:after {
    content: url('/public/images/arrow-hover.svg');
    font-size: 0;
}

.Price-home .btn-right-slide {
    filter: FlipH;
    width: 56px;
    height: 56px;
    position: relative;
}

.Price-home .btn-right-slide:hover {
    -moz-transform: scaleX(1);
    /* Gecko */
    -o-transform: scaleX(1);
    /* Opera */
    -webkit-transform: scaleX(1);
    /* Webkit */
    transform: scaleX(1);
    /* Standard */

    filter: FlipH;
}

.Price-home .btn-right-slide {
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 111;
}

.Price-home .btn-right-slide:after {
    content: url('/public/images/arrow.svg');
    position: absolute;
}

.course-home .swiper-wrapper {
    align-items: center;
}

.Price-home .swiper-wrapper {
    align-items: center;
}

.Price-home .item.item-last {
    border: 0 !important;
    background: none;
}

.Price-home .btn-left-slide, .other-slider-new .btn-left-slide {
    position: absolute;
    top: 0px;
    right: 92px;
    z-index: 111;
}

header .menu-header ul li {
    position: relative;
}

header .menu-header ul li .sub-login {
    position: absolute;
    /* background: #FCB900 0% 0% no-repeat content-box; */
    border-radius: 12px;
    display: none;
    width: 224px;
    right: 0px;
    z-index: 99;
    padding-top: 24px;
    /* visibility: hidden; */
    /* opacity: 0; */
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

header .menu-header ul li.li-last:hover .sub-login, header .menu-header ul li.li-last .sub-login:hover {
    display: inline-block;
    /* opacity: 1; */
}

header.header-after-login .menu-header ul li.li-last .sub-login li {
    width: 100%
}

header.header-after-login .menu-header ul li.li-last .sub-login li a {
    font: normal normal normal 16px/26px Yu Gothic;
    letter-spacing: 0px;
    background: #FCB900 0% 0% no-repeat;
    color: #1A2C44;
    width: 100%;
    display: inline-block !important;
    padding: 20px 30px;
    width: 100%;
    border-left: 1px solid #FCB900;
    border-right: 1px solid #FCB900;
}

header.header-after-login .menu-header ul li.li-last .sub-login li a:hover {
    background: #fff
}

header.header-after-login .menu-header ul li.li-last .sub-login li:first-child a {
    border-top: 1px solid #FCB900;
    border-radius: 12px 12px 0 0;
}

header.header-after-login .menu-header ul li.li-last .sub-login li:last-child a {
    border-radius: 0 0 12px 12px;
    border-bottom: 1px solid #FCB900;
}

header.header-after-login .menu-header ul li.li-last .sub-login li img {
    margin-right: 12px;
}

header.header-after-login .menu-header ul li.li-last .li-infor-user .user-icon {
    position: relative;
    cursor: pointer;
    min-width: 50px;
}

header.header-after-login .menu-header ul li.li-last .li-infor-user .user-icon .user-warning-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -5px;
    right: 0;
}

.main-detail-account .content-detail-account .col-md-3 {
    width: 253px
}

.main-detail-account .content-detail-account .col-md-9 {
    width: calc(100% - 253px);
}

.main-student-list .top-student-list .content-top-student {
    background: #F5F6F8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 28px 80px;
    margin-top: 35px;
}

.main-student-list .top-student-list .content-top-student .item label {
    width: 112px;
    display: inline-block;
    float: left;
    margin-right: 20px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-top: 10px;
}

.main-student-list .top-student-list .content-top-student .item input[type=text] {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px
}

.main-student-list .top-student-list .content-top-student .item .calendar-custom {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px
}

.main-student-list .top-student-list .content-top-student .item {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
}

.main-student-list .top-student-list .content-top-student .item .select-status-student {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px
}

.main-student-list .top-student-list .content-top-student .item .select-group-filter {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.main-student-list .top-student-list .content-top-student .item input[type=date] {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px
}

.main-student-list .top-student-list .content-top-student input[type=submit], .button-common {
    width: 142px;
    height: 44px;
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 28px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
}

.main-student-list .top-student-list .title-primary {
    margin-top: 32px
}

.main-student-list .content-student-list select {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    width: 184px;
    height: 44px;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    padding-left: 10px;
}

.main-student-list .content-student-list .filter-list li {
    display: inline-block;
    float: left;
}

.main-student-list .content-student-list .filter-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.main-student-list .content-student-list .filter-list li a {
    text-decoration: inherit;
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    padding-left: 14px;
    margin-left: 14px;
    display: inline-block;
    position: relative;
}

.btn-disable {
    opacity: 0.5;
    cursor: not-allowed;
}

.main-student-list .content-student-list .filter-list li .filter-list-last:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 17px;
    background: #ddd;
    left: 0;
    top: 8px;
}

.main-student-list .content-student-list .filter-list li:first-child a:before {
    display: none;
}

.main-student-list .content-student-list .filter-list li img {
    margin-right: 5px;
}

.main-student-list .content-student-list .filter-list {
    display: inline-block;
    float: right;
}

.main-student-list .content-student-list .filter-list ul {
    display: inline-block;
    margin-top: 7px;
}

.main-student-list .content-student-list {
    padding-top: 38px;
}

.main-student-list .content-student-list .table-table table {
    width: 100%;
    border-bottom: 1px solid #ddd;
}

.main-student-list .content-student-list .table-table tr td {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 1px solid #d8dbe0;
    padding: 9px 13px;
    text-align: center;
}

.main-student-list .content-student-list .table-table tr td input[type=checkbox] {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 24px;
    height: 24px;
}

.main-student-list .content-student-list .table-table tr .yellow {
    color: #FC8F00;
}

.main-student-list .content-student-list .table-table tr .blue {
    color: #028BD4;
}

.main-student-list .content-student-list .table-table {
    margin-top: 17px;
}

.main-student-list .content-student-list .table-table tr td {
    background: #f5f6f8;
}

.main-student-list .content-student-list .table-table tr:nth-child(2n) td {
    background: #fdfdfe;
}

.main-student-list .content-student-list .table-table tr:first-child td {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    background: #dadde2;

}

.pagenavi .next {
    text-align: right;
}

.pagenavi .next ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
}

.pagenavi .next ul li {
    display: inline-block;
    float: left;
}

.pagenavi .next ul li a {
    display: inline-block;
    border: 1px solid #ddd;
    height: 40px;
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    opacity: 1;
    line-height: 40px;
    width: 40px;
    text-align: center;
    text-decoration: inherit;
    border-left: 0
}

.pagenavi .next ul li.pr a {
    width: 80px;
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #ddd;
}

.pagenavi .next ul li.nx a {
    width: 80px;
    border-radius: 0px 4px 4px px;

}

.pagenavi {
    margin-top: 33px
}

.pagenavi .prev span {
    font: normal normal 500 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-student-list {
    padding-bottom: 138px
}

.main-student-list .top-student-list .content-top-student input[type=submit], .button-common {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-student-list .top-student-list .content-top-student input[type=submit]:hover {
    background: #1A2C44;
    color: #fff;
}

.pagenavi .next ul li.active a {
    background: #FCB900 0% 0% no-repeat padding-box;
    font-weight: bold;
    color: #1A2C44;
}

.pagenavi .next ul li:hover a {
    background: #FCB900 0% 0% no-repeat padding-box;
    font-weight: bold;
    color: #1A2C44;
}

#sendMessageScreenModal .modal-dialog {
    max-width: 1200px;
}

#sendMessageScreenModal .modal-dialog .content-pupop {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left {
    width: 350px;
    background: #F5F6F8 0% 0% no-repeat padding-box;
    padding: 20px 23px;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-right {
    width: calc(100% - 350px);
}

#sendMessageScreenModal .modal-dialog .modal-header .title-1 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 0;
    text-align: center;
}

#sendMessageScreenModal .modal-dialog .modal-header {
    display: inline-block;
    text-align: center;
    padding: 13px;
}

#sendMessageScreenModal .modal-dialog .content-right label {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    display: inline-block;
    width: 100%;
}

#sendMessageScreenModal .modal-dialog .content-right {
    padding: 30px 24px 30px 34px;
}

#sendMessageScreenModal .modal-dialog .content-right input[type=text] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-bottom: 12px;
    padding-left: 10px;
}

#sendMessageScreenModal .modal-dialog .content-right select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    margin-bottom: 12px;
    padding-left: 10px;
}

#sendMessageScreenModal .modal-dialog .content-right textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 100%;
    height: 145px;
    padding-left: 10px;
}

#sendMessageScreenModal .modal-dialog .content-right .link-link button {
    display: inline-block;
    width: 224px;
    height: 56px;
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 28px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    text-align: center;
    line-height: 56px;
    text-decoration: inherit;
    border: none;
    outline: none;
}

#sendMessageScreenModal .modal-dialog .content-right .link-link button:hover {
    background: #1A2C44;
    color: #fff;
}

#sendMessageScreenModal .modal-dialog .content-right .link-link .link-2 {
    background: #FCB900 0% 0% no-repeat padding-box;
    margin-left: 20px;
}

#sendMessageScreenModal .modal-dialog .content-right .link-link {
    margin-top: 32px;
    text-align: center;
}

#sendMessageScreenModal .modal-body {
    padding: 0;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left {
    position: relative;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left .titl-3 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 300px;
    overflow: auto;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul li a {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    text-decoration: inherit;
    padding: 8px 10px;
    display: inline-block;
    background: #0077B633 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 100%;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul li a i {
    float: right;
    margin-top: 5px;
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul li {
    margin-bottom: 10px;
    width: 100%;
}

#sendMessageScreenModal .modal-dialog .content-pupop .desc-1 {
    font: normal normal bold 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    margin: 0;
    position: absolute;
    bottom: 20px;
}

#sendMessageScreenModal {
    background-color: rgba(0, 0, 0, 0.6);
}

#sendMessageScreenModal .modal-dialog .content-pupop .content-left ul li a:hover {
    background: #1A2C44;
    color: #fff;

}

.main-student-list .top-student-list .content-top-student .row {
    margin-left: -30px;
    margin-right: -30px
}

.main-student-list .top-student-list .content-top-student .row .col-md-6 {
    padding-left: 30px;
    padding-right: 30px;
}

.main-view-student-detail .title-title {
    border-bottom: 1px solid #ddd;
    height: 31px;
    position: relative;
}

.main-view-student-detail .title-title .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    position: absolute;
    top: 17px;
    left: 0;
    background: #fff;
    padding-right: 10px;
    margin: 0;
}

.main-view-student-detail .content-student-detail {
    width: 808px;
    margin: auto;
    margin-top: 65px;
}

.main-view-student-detail .content-student-detail .item {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px dashed #DADDE2;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.main-view-student-detail .content-student-detail .item .left {
    width: 50%;
    padding-right: 10px;
    padding-left: 24px
}

.main-view-student-detail .content-student-detail .item .left span {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-view-student-detail .content-student-detail .item .right {


    width: 50%;
}

.main-view-student-detail .content-student-detail .item .right span {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    word-break: break-all;
}

.main-view-student-detail .content-student-list {
    padding-top: 62px;
}

.main-view-student-detail .filter-2 {
    text-align: right;
    margin-top: 25px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-view-student-detail .filter-2 span {
    display: inline-block;
    margin-right: 10px
}

.main-student-list.main-view-student-detail {
    padding-bottom: 149px;
}

header.header-after-login .menu-header ul li.li-last a .nav-icon.user-text {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    display: inline-block;
    margin-top: 8px;
    margin-right: 127px;
}

.main-view-student-detail .content-student-list {
    position: relative;
}

.main-view-student-detail .content-view-student-detail {
    padding-top: 216px
}

.main-view-student-detail.main-student-list .content-student-list .table-table tr td {
    border-bottom: 0;
    border-top: 0
}

.main-view-student-detail .content-view-student-detail-2 {
    padding-top: 107px;
}

.main-student-list.main-view-student-detail {
    padding-bottom: 157px;
}

.main-view-detail-information .title-title .title-2 {
    font: normal normal bold 40px/56px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

}

.main-view-detail-information.main-view-student-detail .title-title {
    height: 60px;
}

.main-view-detail-information.main-view-student-detail .title-title .edit {
    position: absolute;
    top: 44px;
    right: 0;
    font: normal normal 500 16px/26px Yu Gothic;
    color: #363946;
    text-decoration: inherit;
    background: #fff;
    display: inline-block;
    padding-left: 17px;
}

.main-view-detail-information.main-view-student-detail .title-title .edit img {
    margin-right: 6px;
}

.main-view-student-detail.main-view-detail-information {
    padding-bottom: 150px;
}

.main-view-student-detail.main-view-detail-information .top-student-list {
    padding-top: 14px;
}

.main-update .box-form-account {
    max-width: 972px;
    margin: auto;
}

.main-account-trgisstration-1.main-update .form-account {
    margin-top: 40px;
}

.main-account-trgisstration-4.main-update .box-form-account .item .row {
    margin-left: -15px;
    margin-right: -15px;
}

.main-account-trgisstration-4.main-update .box-form-account .item .row .col-md-6 {
    padding-left: 15px;
    padding-right: 15px;
}

.main-account-trgisstration-4.main-update .box-form-account .item .item-left {
    width: 150px
}

.main-account-trgisstration-4.main-update .box-form-account .item .item-right {
    width: calc(100% - 150px);

}

.main-account-trgisstration-2 .box-form-account .item .item-right input[type=text]::-webkit-input-placeholder {
    /* Edge */
    color: #333;
}

.main-account-trgisstration-2 .box-form-account .item .item-right input[type=text]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #333;
}

.main-account-trgisstration-2 .box-form-account .item .item-right input[type=text]::placeholder {
    color: #333;
}

.main-update .link-button {
    margin-top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.main-update .link-button button {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 301px;
    height: 56px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    min-width: 200px;
}

.main-update .link-button .btn-1 {
    background: #DADDE2 0% 0% no-repeat padding-box;
}

.main-update .link-button button:hover {
    background: #FCB900
}

.main-update .link-button .btn-2:hover {
    background: #fff;
    color: #000;
    border: 1px solid #FCB900;


}

/* .main-update .link-button .btn-2 {
    margin-left: 20px;
} */

.main-view-student-detail.main-update {
    padding-bottom: 148px
}

.main-view-student-detail.main-content-home-2 {
    padding-bottom: 181px;

}

.main-student-list .content-student-list .table-table {
    margin-top: 35px;
}

/* .slider-price .btn-slide {
    display: none
} */

.main-execute-completion .content-execute-completion .title-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-bottom: 0;
}

.main-execute-completion .nav-execute-completion .item-execute .title-4 {
    font: normal normal 500 18px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    position: relative;
    padding-left: 32px;
    margin-bottom: 18px;
    white-space: pre-line;
}

.main-execute-completion .nav-execute-completion .item-execute .title-4 span {
    position: absolute;
    top: 0;
    left: 0;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute ul li {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-bottom: 9px;
    position: relative;
    display: flex;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute ul li label {
    flex: 1;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute ul li .icon-1 {
    position: absolute;
    left: -38px;
    top: -2px;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute {
    padding-left: 100px;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute ul li input[type=radio] {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    margin-top: 2px;
}

.main-execute-completion .nav-execute-completion .item-execute {
    border-top: 1px solid #ddd;
    padding-top: 25px;
    margin-top: 40px;
}


.main-execute-completion .link-button {
    margin-top: 62px;
    text-align: center;
}

.main-execute-completion .link-button button {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 32px;
    width: 301px;
    height: 56px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
}

.main-execute-completion .link-button .btn-1 {
    background: #DADDE2 0% 0% no-repeat padding-box;
}

.main-update .link-button button:hover {
    background: #FCB900
}

.main-execute-completion .link-button .btn-2:hover {
    background: #fff;
    color: #000;
    border: 1px solid #FCB900;


}

.main-execute-completion .link-button .btn-2 {
    margin-left: 20px;
}

.main-execute-completion .nav-execute-completion {
    border-bottom: 1px solid #ddd;
    padding-bottom: 34px;
}

.main-execute-completion {
    padding-bottom: 148px;
}

.main-execute-completion .link-button .btn-1:hover {
    background: #FCB900;
}

.main-execute-completion .content-execute-completion {
    margin-top: 42px;
}

.main-execute-completion .content-execute-completion .top-title-date .date {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    text-align: right;
    margin: 0;
    margin-top: 10px;
}

.main-execute-completion .content-execute-completion .top-title-date .date span {
    font-weight: bold;
}

.main-execute-completion-2 .chart-map .svg-content {
    width: 220px;
    height: 220px;
}

.main-execute-completion-2 .chart-map {
    text-align: center;
}

.main-execute-completion-2 .chart-map .title-2 {
    font: normal normal bold 20px/30px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-top: 30px;
}

.main-execute-completion-2 .chart-map .desc {
    color: #363946;
}

.main-execute-completion-2 .chart-map .desc span {
    font-weight: bold;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute .note {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: red !important;
    /* color: #363946; */
    background: #F5F6F8 0% 0% no-repeat padding-box;
    display: inline-block;
    padding: 4px 13px;
    margin: 0;
}

.main-execute-completion .nav-execute-completion .item-execute .list-execute .note a {
    font-weight: bold;
    color: red !important;
}

.main-view-lecture .content-view-lecture .title-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    color: #F21313;
    margin-bottom: 0;
    padding: 0;
}

.main-view-lecture .content-view-lecture .date {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;

}

.main-view-lecture .content-view-lecture .iframe-youtube {
    text-align: center;
}

.main-view-lecture .content-view-lecture .iframe-youtube iframe {
    width: 768px;
    height: 432px;
}

.main-view-lecture .content-view-lecture {
    padding-top: 45px;
}

.main-view-lecture .content-view-lecture .item-view-lecture .title-1 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-view-lecture .content-view-lecture .item-view-lecture p {
    /* font: normal normal normal 16px/26px Yu Gothic; */
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-view-lecture .content-view-lecture .item-view-lecture {
    padding-top: 30px
}

.main-view-lecture .content-view-lecture .iframe-youtube {
    margin-bottom: 70px
}

.main-view-lecture .content-view-lecture .read-more {
    text-align: center;
}

.main-view-lecture .content-view-lecture .read-more p {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    text-decoration: inherit;
    cursor: pointer;
}

.main-view-lecture .content-view-lecture .read-more a i {
    margin-left: 5px;
}

.main-view-lecture .ic-new {
    width: 50px;
    object-fit: contain;
    margin-top: -30px;
}

.other-slider-new .btn-left-slide {
    top: -75px !important;
}
.other-slider-new .slider-course .btn-right-slide {
    top: -75px !important;
}

.other-slider-new .title-primary {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.other-slider-new .slider-course .item .title-category-1 {
    margin-top: 6px;
    margin-bottom: 0;
}

.other-slider-new .slider-course .item .title-category-1 a {
    font: normal normal 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
    text-decoration: inherit;
    font-weight: bold;
}

.other-slider-new .slider-course .item .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-top: 1px;
}

.other-slider-new .slider-course .item .title-2 .ic-new2 { 
    height: 29px;
    object-fit: contain;
}

.other-slider-new .slider-course {
    margin-top: 29px;
}

.main-execute-completion.main-view-lecture {
    padding-bottom: 128px;
}

.other-slider-new .title-primary {
    border-top: 1px solid #ddd;
    padding-top: 27px;
}

.main-view-lecture .other-slider-new {
    padding-top: 100px
}

.main-Purchase-ticket .Price-home:before {
    display: none
}

.main-Purchase-ticket .Price-home {
    margin-top: 0;
    padding-top: 36px;
}

.main-Purchase-ticket .Price-home .description {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    max-width: 100%;
    padding-top: 7px;
}

.main-Purchase-ticket .Price-home .slider-price {
    margin-top: 35px;
}

.main-execute-completion.main-Purchase-ticket {
    padding-bottom: 234px;
}

.main-Pay-ment .content-content {
    width: 634px;
    margin: auto;
    margin-top: 63px;
}

.main-Pay-ment .content-content .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-align: center;
    margin-bottom: 20px;
}

.main-Pay-ment .content-content .title-l {
    font: normal normal bold 16px/34px Yu Gothic;
    color: #363946;
    margin: 0;
}

.main-Pay-ment .content-content .title-r {
    font: normal normal bold 16px/34px Yu Gothic;
    color: #363946;
    text-align: right;
    margin: 0;
}

.main-Pay-ment .content-content .item-1.item-4 p {
    color: #F21313;
}

.main-Pay-ment .content-content .item-2 .ma {
    margin: 0;
    height: 48px;
    background: #F5F3F0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-left: 15px;
    font: normal normal 500 16px/48px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-Pay-ment .content-content .item-2 .ma span {
    float: right;
    margin-right: 15px;
}

.main-Pay-ment .content-content .item-2 .title-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;

}

.main-Pay-ment .content-content .item-2 {
    margin-top: 20px;
    border-bottom: 0px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 13px;
}

.main-Pay-ment .content-content .link-link {
    text-align: center;
    margin-top: 17px;
}

.main-Pay-ment .content-content .link-link button {
    height: 56px;
    width: 190px;
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 28px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    border: 1px solid #FCB900;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-Pay-ment .content-content .link-link button:hover {
    background: #fff
}

.qty {
    width: 54px;
    height: 32px;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

input.qtyplus {
    background: #DADDE2 0% 0% no-repeat padding-box;
    width: 32px;
    height: 32px;
    border: 0;
    font-weight: bold;
    font-size: 20px;
}

input.qtyminus {
    background: #DADDE2 0% 0% no-repeat padding-box;
    width: 32px;
    height: 32px;
    border: 0;
    font-weight: bold;
    font-size: 20px;
}

.main-Pay-ment .content-content #myform {
    display: flex;
    border: 1px solid #DADDE2;
    width: 120px;
    float: right;
}

.main-Pay-ment .content-content .item-1 {
    margin-bottom: 10px;
}

.main-Pay-ment .content-Pay-ment {
    margin-top: 30px;
}

.main-Pay-ment .content-Pay-ment-bottom .content-1 {
    background: #DADDE266 0% 0% no-repeat padding-box;
    padding: 37px 40px;
}

.main-Pay-ment .content-Pay-ment-bottom {
    margin-top: 75px
}

.main-Pay-ment .content-Pay-ment-bottom .content-1 .title-1 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin-bottom: 10px
}

.main-Pay-ment .content-Pay-ment-bottom .content-1 .title-bottom {
    font: normal normal bold 17px Yu Gothic;
    color: #e71313;
}

.main-Pay-ment {
    padding-bottom: 129px
}

.main-Message-list .title-filter .filter-right ul {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    display: flex;
}

.main-Message-list .title-filter .filter-right ul li {
    display: inline-block;
    float: left;
}

.main-Message-list .title-filter .filter-right {
    text-align: right;
}

.main-Message-list .title-filter .filter-right ul li.li-filter button {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #FCB900;
    margin-left: 38px;
    min-width: 128px;
    font: normal normal bold 16px/26px Yu Gothic;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-Message-list .title-filter .filter-right ul li.li-filter button:hover {
    color: #1A2C44;
    background: #fff;
}

.main-Message-list .title-filter .filter-right ul li.li-check {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-Message-list .title-filter .filter-right ul li.li-check input[type=checkbox] {
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 10px;
}

.main-Message-list .title-filter .filter-right ul li.li-check {
    margin-left: 36px
}

.main-Message-list .title-filter .filter-right ul li select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 150px;
    height: 44px;
    padding-left: 10px;
}

.main-Message-list .title-filter .filter-right ul li.msg-cate select {
    width: 275px;
}
.main-Message-list .title-filter .filter-right ul li.msg-seen select {
    margin-left: 20px;
}

.main-Message-list .title-filter .title-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 0;
    margin-top: 10px;

}

.main-Message-list .content-message .item-message .title-2 {
    font: normal normal bold 18px/30px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-bottom: 0;
}

.main-Message-list .content-message .item-message .date {
    font: normal normal 500 14px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    margin-bottom: 8px;
}

.main-Message-list .content-message .item-message .desc-1 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

    margin: 0;
    padding-bottom: 20px;

}

.main-Message-list .content-message .item-message {
    position: relative;
    padding-left: 63px;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
}

.main-Message-list .content-message .item-message:first-child {
    border-top: 1px solid #ddd;
}

.main-Message-list .content-message .item-message:before {
    content: '';
    background: #0077B6 0% 0% no-repeat padding-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    left: 22px;
}

.main-Message-detail .content-Message-detail  .title-secondary,
.main-Message-list .content-message .item-message  .title-secondary {
    color: #f21313 ;
    font: normal normal 500 14px/26px Yu Gothic;
}

.main-Message-list .content-message .item-message-2  .title-secondary {
    opacity: 0.7;
}

.main-Message-list .content-message .item-message-2 {
    /* background: #f8f8f9; */
}

.main-Message-list .content-message .item-message-2 .title-2 {
    color: #777;
}

.main-Message-list .content-message .item-message-2 .desc-1 {
    color: #777;
}

.main-Message-list .content-message .item-message-2 .date {
    color: #777;
}

.main-Message-list .content-message .item-message-2:before {
    background: #fff 0% 0% no-repeat padding-box;
}

.main-Message-list .content-message {
    margin-top: 14px;
}

.main-Message-list {
    padding-bottom: 140px;
}

.main-Message-list .title-filter {
    margin-top: 10px;
}

.main-Message-list .read-more {
    color: #0a58ca;
    text-decoration: none;
}

.main-Message-list .read-more:hover {
    color: #FCB900;
    text-decoration: underline;
}

.main-Message-detail .content-Message-detail .date {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    margin-bottom: 0px;
}

.main-Message-detail .content-Message-detail .content-content {
    font-size: 16px;
}
.main-Message-detail .content-Message-detail .title-primary {
    font: normal normal bold 25px/40px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 20px 0px;
}

.main-Message-detail .content-Message-detail .content-content p {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-Message-detail .content-Message-detail {
    padding-top: 30px;
}

.main-Message-detail .content-Message-detail .link-link {
    text-align: center;
    margin-top: 47px;
}

.main-Message-detail .content-Message-detail .link-link button {
    height: 56px;
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 28px;

    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    border: 1px solid #DADDE2;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    padding: 0 40px;

}

.main-Message-detail {
    padding-bottom: 148px;
}

.main-Message-detail .content-Message-detail .link-link button:hover {
    background: #FCB900;
    border: 1px solid #FCB900;
}

.main-send-inquiry .box-form-account {
    max-width: 792px;
    border: 1px solid #DADDE2;
    border-radius: 24px;
    background: #fff
}

.main-account-trgisstration-4.main-send-inquiry .box-form-account .item .item-left {
    width: 100%;
    padding-right: 0;
}

.main-account-trgisstration-4.main-send-inquiry .box-form-account .item .item-right {
    width: 100%;
    margin-top: 7px;
}

.main-detail-count-info .top-student-list .col-xl-2 {
    width: 252px;
}

.main-detail-count-info .top-student-list .col-xl-10 {
    width: calc(100% - 252px);
}

.main-detail-count-info .content-student-detail .item .left {
    width: 46%;
}

.main-detail-count-info .content-student-detail .item .right {
    width: 54%;
}

.main-detail-count-info .content-student-detail {
    margin-top: 52px;
    width: 100%;
}

.aside-left .title-admin {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
    display: flex;
    align-items: center;
}

.aside-left .title-admin img {
    margin-right: 16px;
}

.aside-left {
    margin-top: 28px;
}

.aside-left .list-account {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 37px;
}

.aside-left .list-account li {

    letter-spacing: 0px;
    color: #363946;
    position: relative;
    padding-left: 36px;
    margin-bottom: 20px;
}

.aside-left .list-account li a {
    text-decoration: inherit;
    font: normal normal 500 16px/26px Yu Gothic;
}

.aside-left .list-account .li-1:before {
    content: url('/public/images/icon14.svg');
    position: absolute;
    top: 0;
    left: 0;
}

.aside-left .list-account .li-1:hover:before {
    content: url('/public/images/icon14-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account li:hover a {
    font: normal normal bold 16px/26px Yu Gothic;
    color: #FCB900;
}

.aside-left .list-account .li-2:before {
    content: url('/public/images/icon15.svg');
    position: absolute;
    top: 0;
    left: 0;
}

.aside-left .list-account .li-2:hover:before {
    content: url('/public/images/icon-15-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-2.active:before {
    content: url('/public/images/icon-15-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-2.active a {
    color: #FCB900;
}

.aside-left .list-account .li-3:before {
    content: url('/public/images/icon16.svg');
    position: absolute;
    top: 0;
    left: 0;
}

.aside-left .list-account .li-3:hover:before {
    content: url('/public/images/icon16-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-3.active:before {
    content: url('/public/images/icon16-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-3.active a {
    color: #FCB900;
}

.aside-left .list-account .li-4:before {
    content: url('/public/images/icon17.svg');
    position: absolute;
    top: 0;
    left: 0;
}

.aside-left .list-account .li-4:hover:before {
    content: url('/public/images/icon17-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-4.active:before {
    content: url('/public/images/icon17-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-4.active a {
    color: #FCB900;
}

.aside-left .list-account .li-1.active:before {
    content: url('/public/images/icon14-hover.svg');
    position: absolute;
    top: 0;
    left: 0;

}

.aside-left .list-account .li-1.active a {
    color: #FCB900;
}

.main-update-acount .content-signin .col-md-2 {
    width: 252px;
}

.main-update-acount .content-signin .col-md-10 {
    width: calc(100% - 252px);
}

.main-update-acount .box-form-account {
    padding: 48px;
}

.main-update-acount.main-update .box-form-account .item .item-right ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.main-update-acount.main-update .box-form-account .item .item-right ul li:first-child {
    margin-right: 82px;
}

.main-update-acount.main-update .box-form-account .item .item-right ul li input[type=radio] {
    margin-right: 16px;
    width: 20px;
    height: 20px;
    float: left;
}

.main-update-acount.main-update .box-form-account .item .item-right .check-box p {
    margin-bottom: 5px;
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-update-acount.main-update .box-form-account .item .item-right .check-box input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    float: left;
}

.main-update-acount.main-update .box-form-account .item:last-child {
    display: inline-block;
    width: 100%;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.main-update-acount.main-update .box-form-account .item:last-child .item-left label {
    margin-top: 0;
}

.main-Attended-couse .top-student-list .col-md-2 {
    width: 252px;
}

.main-Attended-couse .top-student-list .first-filter {
    margin-left: -15px;
}

.main-Attended-couse .top-student-list .col-md-10 {
    width: calc(100% - 252px);
}

.main-Attended-couse .top-student-list .content-top-student {
    padding: 38px
}

.main-Attended-couse .top-student-list .content-top-student .item .list-check {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
    width: calc(100% - 132px);
    /* padding-left: 15px; */
}

.main-Attended-couse .top-student-list .content-top-student .item .list-check input[type="checkbox"] {
    width: 24px;
    height: 24px;
    display: inline-block;
    float: left;
    margin-right: 12px;
}

.main-Attended-couse .top-student-list .content-top-student .item-22 label {
    margin-top: 0;
}

.main-Attended-couse .top-student-list .content-top-student .item-22 {
    margin-top: 5px;
    margin-bottom: 25px;
}

.main-Attended-couse .content-student-list .titl-4 {
    font: normal normal 500 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
    margin-top: 10px;
}

.main-Attended-couse .content-student-list .table-table {
    margin-top: 16px;
}

.main-Attended-couse .content-student-list .table-table tr td span {
    color: #0077B6;
}

.main-Attended-couse .content-student-list .filter-list li i {
    margin-right: 10px;
    font-size: 18px;
}

.main-Attended-couse .content-student-list .filter-list li a:hover {
    color: #FCB900;
}

.main-Attended-couse .content-student-list .filter-list li .btn-export {
    height: 44px;
    border-radius: 6px;
    background: green 0% 0% no-repeat padding-box;
    font: normal normal bold 14px/44px Yu Gothic;
    letter-spacing: 0px;
    border: 1px solid green;
    color: #fff;
    padding: 0px 8px;
}

.main-Attended-couse .content-student-list .filter-list li .btn-export1 {
    background: #FCB900 0% 0% no-repeat padding-box;
    border: 1px solid #FCB900;
    font-size: 16px;
}

.main-Attended-couse .content-student-list .filter-list li .btn-export:hover {
    background: #fff;
    color: green;
}

.main-Attended-couse .content-student-list .filter-list li .btn-export1:hover {
    color: #FCB900;
    
}

.main-view-course .content-view-course>.container>.row>.col-md-2 {
    width: 230px;
}

.main-view-course .content-view-course>.container>.row>.col-md-10 {
    width: calc(100% - 230px);
}

.sidebar-primary .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;

    margin-bottom: 14px;

}

.sidebar-primary form {
    position: relative;
}

.sidebar-primary form input[type="text"] {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 44px;
    /* text-indent: 10px; */
    padding-left: 10px;
    width: 100%;

}

.sidebar-primary form input[type="text"]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DADDE2;
    opacity: 1;
    /* Firefox */
}

.sidebar-primary form input[type="text"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #DADDE2;
}

.sidebar-primary form input[type="text"]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #DADDE2;
}

.sidebar-primary form button {
    position: absolute;
    top: 1px;
    right: 1px;
    border: 0;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    height: 42px;
    padding-left: 10px;
    display: block;
    border-radius: 4px;
}

.sidebar-primary .list-new ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-primary .list-new ul li {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.sidebar-primary .list-new ul li a {
    text-decoration: initial;
}

.sidebar-primary .list-new {
    margin-top: 24px;
}

.sidebar-primary .list-new ul li a:hover {
    font: normal normal bold 16px/26px Yu Gothic;
    color: #FCB900;
}
.sidebar-primary .list-new ul li a.active{
    font: normal normal bold 16px/26px Yu Gothic;
    color: #FCB900;
}

.sidebar-primary .list-new ul li.readmore {
    text-align: right;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.sidebar-primary .list-new ul li.readmore a {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
}

.sidebar-primary .list-new ul li.readmore a i {
    margin-left: 5px;
}

.main-view-course .content-view-course .view-course-right {
    padding-left: 12px;
}

.main-view-course .content-view-course .view-course-right .title-primary {
    font: normal normal bold 40px/56px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-view-course .content-view-course .view-course-right .desc {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    padding-top: 20px;
}

.main-view-course .content-view-course .view-course-right .title-search .public-date-filter {
    position: relative;
}

.main-view-course .content-view-course .view-course-right .title-search .public-date-filter select {
    border: none;
}

.main-view-course .content-view-course .view-course-right .public-date-filter .public-date-filter-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    left: 10px;
}

.main-view-course .content-view-course .view-course-right .public-date-filter .public-date-icon-mobile {
    display: none;
}

.main-view-course .content-view-course .title-search .title-2 {
    font: normal normal bold 14px/22px Yu Gothic;
    color: #363946;
}

.main-view-course .content-view-course .title-search form {
    position: relative;
    float: right;
}

.main-view-course .content-view-course .title-search form input[type=text] {
    width: 268px;
    height: 44px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    padding-left: 10px
}

.main-view-course .content-view-course .title-search form input[type=text]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DADDE2;
    opacity: 1;
    /* Firefox */
}

.main-view-course .content-view-course .title-search form input[type=text]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #DADDE2;
}

.main-view-course .content-view-course .title-search form input[type=text]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #DADDE2;
}

.main-view-course .content-view-course .title-search form button {
    position: absolute;
    right: 1px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 0;
    top: 1px;
    height: 42px;
}

.main-view-course .content-view-course .content-course-right .item .img {
    width: 320px;
    display: inline-block;
    float: left;
}

.main-view-course .content-view-course .content-course-right .item .img img{
    width: 100%;
    aspect-ratio: 16/9;
}

.main-view-course .content-view-course .content-course-right .item .nav-img {
    width: calc(100% - 320px);
    display: inline-block;
    float: left;
    padding-left: 28px;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .title-3 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .title-3 a {
    text-decoration: inherit;
}
.main-view-course .content-view-course .content-course-right .item .nav-img .title-3 a:hover {
    color: #FCB900 !important;
}
.main-view-course .content-view-course .content-course-right .item .nav-img .read-more {
    color: #FCB900 !important;
    text-align: right;
}
.main-view-course .content-view-course .content-course-right .item .read-more-view {
    text-align: right;
}
.main-view-course .content-view-course .content-course-right .item .nav-img .read-more:hover {
    color: #FCB900 !important;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .title-category .theme-name {
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #0077B6 0% 0% no-repeat padding-box;
    text-decoration: inherit;
    padding: 4px 10px;
    border: 1px solid #0077B6;
    width: auto;
}

.item-theme .title-category {
    background: none !important;
    color: #0077B6;
    margin-bottom: 0px;
    font-size: 14px;
    font: normal normal bold 14px/28px Yu Gothic;
}

.item-theme .title-intro {
    color: #333;
    margin-bottom: 0px;
    font-size: 21px;
    font: normal normal bold 21px/30px Yu Gothic;
}

.item-theme .chart-area {
    /* padding-top: 20px !important; */
}

.item-theme .item-course-status {
    padding: 2px 15px;
    line-height: 30px;
    margin-bottom: 15px;
    font: normal normal bold 14px/28px Yu Gothic;
    color: #fff;
}

.item-related-lecture .item-course-status, .lt-theme-item .item-course-status {
    padding: 5px 15px;
    font: normal normal bold 14px/30px Yu Gothic;
    color: #fff;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .title-category .theme-name:hover {
    background: #fff !important;
    color: #0077B6 !important;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .join-course {
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    /* background: #0077B6 0% 0% no-repeat padding-box; */
    background-color: #fff;
    text-decoration: inherit;
    padding: 0px 10px;
    border: 1px solid #0077B6 !important;
    width: auto;
    min-width: 100px;
    height: 38px;
    /* border-radius: 5px; */
}

.main-view-course .content-view-course .content-course-right .item .nav-img .join-course:hover {
    background: #0077B6 !important;
    color: #fff !important;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .clock-video ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .clock-video ul li img {
    width: auto;
    display: inline-block;
    float: left;
    margin-right: 8px;
    margin-top: 4px;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .clock-video ul li {
    margin-right: 22px;
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .dessc-2 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-bottom: 10px;
}

.item-theme .dessc-2 {
    font: inherit !important;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .dessc-2 a {
    font-weight: bold;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .dessc-2 a:hover {
    text-decoration: underline !important;
    color: #FCB900 !important;
}

.main-view-course .content-view-course .content-course-right .item .img img {
    width: 100%;
}

.main-view-course .content-view-course .content-course-right .item .img {
    overflow: hidden;
}

.main-view-course .content-view-course .content-course-right .item:hover .img img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.main-view-course .content-view-course .content-course-right .item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 35px;
    margin-bottom: 35px;
}

.main-view-course .content-view-course .title-search {
    border-bottom: 1px solid #ddd;
    padding-bottom: 35px;
    margin-bottom: 35px;
    margin-top: 24px
}

.main-course-detail .content-view-course .view-course-right .desc-3 {
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
    margin: 0;
}

.main-course-detail .content-view-course .view-course-right .desc-3 img {
    margin-right: 10px;
}

.main-course-detail .content-view-course .view-course-right .date {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0
}

.main-course-detail .content-view-course .view-course-right .date span {
    font-weight: bold;
}

.main-course-detail .content-view-course .view-course-right .btn-3 {
    width: 208px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #0077B6;
    border-radius: 28px;
    opacity: 1;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    float: right;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.main-course-detail .content-view-course .content-course-right .item .nav-img .read-more-2:hover {
    color: #0077B6;

}

.main-course-detail .content-view-course .view-course-right .btn-3:hover {
    background: #0077B6;
    color: #fff;
}

.main-course-detail .content-view-course .view-course-right .iframe-youtube {
    text-align: center;
    margin-top: 104px;
}

.main-course-detail .content-view-course .view-course-right .iframe-youtube iframe {
    width: 640px;
    height: 360px
}

.main-course-detail .content-view-course .view-course-right .content-content h3 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    padding-top: 30px;
    color: #1A2C44;
}

.main-course-detail .content-view-course .view-course-right .content-content p {
    font: normal normal normal 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-course-detail .content-view-course .view-course-right .desc {
    padding-top: 0;
}

.main-course-detail .top-top {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-top: 100px;
    padding: 20px 0;
    margin-bottom: 30px;
}

.main-course-detail .top-top .title-2 {
    font: normal normal bold 24px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    margin: 0;
}

.main-course-detail .top-top ul li {
    font: normal normal 500 16px/26px Yu Gothic;

    color: #363946;
    margin-left: 34px;
}

.main-course-detail .top-top ul {
    display: flex;
    list-style: none;
    justify-content: end;
    margin: 0;
    padding: 0
}

.main-course-detail .top-top ul li span {
    font-weight: bold;
}

.main-course-detail .content-view-course .content-course-right .item .nav-img .read-more-2 {
    font: normal normal bold 16px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    float: right;
    cursor: pointer;
    text-decoration: underline !important;
}

.main-course-detail .content-view-course .content-course-right .item .nav-img .read-more-2 i {
    margin-left: 5px;
}

.main-course-detail .content-view-course .content-course-right .item .nav-img .dessc-2 {
    margin-top: 10px
}

.main-course-detail .content-view-course .content-course-right .item .nav-img .title-3 .v {
    float: right;
    position: relative;
    top: -12px;
}


.main-fqa .title-primary {
    text-align: center;
    padding-bottom: 25px;
}

.main-fqa .section-fqa {
    padding-top: 32px;
}

/* Accordion
================================================== */

.main-fqa .acc__card {

    position: relative;
}

.main-fqa .acc__title {


    cursor: pointer;
    display: block;
    position: relative;
    text-align: left;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;

}

.main-fqa .acc__card.active .acc__title {
    color: #FCB900;
    font-weight: bold;
}

.main-fqa .acc__card.active .acc__title:after {
    border-right: 1px solid #FCB900;
    border-bottom: 1px solid #FCB900;
}

.main-fqa .acc__title:after {
    width: 8px;
    height: 8px;
    border-right: 1px solid #4a6e78;
    border-bottom: 1px solid #4a6e78;
    position: absolute;
    right: 10px;
    content: " ";
    top: 8px;
    transform: rotate(-45deg);
    transition: all 0.2s ease-in-out;
}

.main-fqa .acc__title.active:after {
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;
}

.main-fqa .acc__panel {

    display: none;
    margin: 0;

    text-align: left;
}

.main-fqa .acc__panel ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;

}

.main-fqa .acc__panel ul li {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.main-fqa .row>.col-md-2 {
    width: 210px;
}

.main-fqa .row>.col-md-10 {
    width: calc(100% - 210px);
}

.main-fqa .acc__panel ul li a {
    text-decoration: inherit;
}

.main-fqa ul li a:hover {
    color: #FCB900;
}

.main-send-inquiry .content-signin .title-2 {
    font: normal normal bold 40px/65px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-align: center;
}

.main-send-inquiry .content-signin .desc-2 {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    text-align: center;
}

.main-send-inquiry .content-signin .desc-2 a {
    color: #10A9FB;
}

.main-send-inquiry .content-signin {
    padding-top: 30px;
}

.main-send-inquiry .box-form-account {
    padding: 40px 84px;
}

.main-send-inquiry.main-account-trgisstration-4 .box-form-account .item {
    padding-bottom: 30px;
    margin-bottom: 0;
    border-bottom: 0;
}

.main-send-inquiry .box-form-account .item .item-right textarea, .answer-text {
    width: 100%;
    height: 94px;
    border: 1px solid #DADDE2;
    border-radius: 4px;
}

.main-send-inquiry .content-content .link-button {
    margin-top: 0;
    text-align: center;
}

.main-send-inquiry.main-account-trgisstration-1 .content-content .link-button button.btn-2 {
    background: #FCB900;
    float: none;
    padding: 0;
    margin: 0;
    color: #fff
}

.main-purchasse-history .top-top .title-3 span {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    display: inline-block;
    margin-right: 10px;
}

.main-purchasse-history .top-top .title-3 select {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    width: 180px;
    height: 44px;
    border: 1px solid #DADDE2;
    border-radius: 4px;
}

.main-purchasse-history .top-top form {
    position: relative;
    float: right;
}

.main-purchasse-history .top-top form input[type=text] {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    width: 288px;
    height: 44px;
    padding-left: 52px;
}

.main-purchasse-history .top-top form input[type=text]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DADDE2;
    opacity: 1;
    /* Firefox */
}

.main-purchasse-history .top-top form input[type=text]::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #DADDE2;
}

.main-purchasse-history .top-top form input[type=text]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #DADDE2;
}

.main-purchasse-history .top-top form button {
    position: absolute;
    right: 1px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 0;
    top: 1px;
    height: 42px;
}

.main-purchasse-history .item-purchasse .title-3 {
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 12px 12px 0px 0px;
    margin: 0;
    font: normal normal bold 18px/29px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
}

.main-purchasse-history .item-purchasse {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 12px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse {
    padding: 22px 30px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link {
    display: flex;
    justify-content: flex-end;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button {
    height: 56px;
    width: 190px;
    background: #fff;
    border-radius: 28px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #FCB900;
    border: 0;
    border: 1px solid #FCB900;
    transition: all 0.7s ease;
    margin-top: 25px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button:hover {
    background: #FCB900 0% 0% no-repeat padding-box;
    color: #fff;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .title-4 {
    font: normal normal bold 18px/29px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item {
    display: flex;
    flex-wrap: wrap;

}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1 {
    width: 60%;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-4 {
    width: 60%;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-4 .divider {
    height: 1px;
    width: 100%;
    background-color: #ddd;
    margin-top: 15px;
    margin-bottom: 20px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-2 {
    width: 40%;
    padding-left: 30px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-2 .span-2 {
    text-align: right;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-3 {
    width: 27%;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1 .span-1,
.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-4 .span-1 {
    width: 50%;
    display: inline-block;

    float: left;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-1 .span-2,
.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-4 .span-2 {
    width: 50%;
    display: inline-block;
    float: right;
    font: normal normal medium 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item .item-4 .span-2 {
    text-align: right;
    color: #363946;
    font: normal normal bold 16px/34px Yu Gothic;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item p {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-3 {
    padding-left: 10px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-3 p {
    margin-bottom: 0;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-3 p .span-1 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-3 p .span-2 {
    font: normal normal bold 30px/48px Yu Gothic;
    letter-spacing: 0px;
    color: #FCB900;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-2 .price .span-1 {
    font: normal normal bold 22px/48px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .item-2 .price .span-2 {
    font: normal normal bold 25px/48px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
}

.main-purchasse-history .item-purchasse {
    margin-bottom: 16px;
}

.main-purchasse-history .top-top {
    margin-bottom: 22px;
}

.main-purchasse-history .item-purchasse-paid .title-3 {
    background: #198754 0% 0% no-repeat padding-box;
    color: #fff;
}

.main-purchasse-history .item-purchasse-paid .nav-item-purchasse .item-3 p .span-2 {
    color: #198754;
}


.main-purchasse-history .item-purchasse-unpaid .title-3 {
    background: #DADDE2 0% 0% no-repeat padding-box;
}

.main-purchasse-history .item-purchasse-cancel-request .title-3 {
    background: #47a4d5 0% 0% no-repeat padding-box;
}

.main-purchasse-history .item-purchasse-canceled .title-3 {
    background: #ff7272 0% 0% no-repeat padding-box;
}

.main-purchasse-history .item-purchasse-unpaid .nav-item-purchasse .item-3 p .span-2 {
    color: #0077B6;
}

.hc-offcanvas-nav .nav-close {
    position: absolute;
    right: 0px;
    width: 50px;
    height: 50px;
    background: #fff;
    line-height: 50px;
}

.hc-offcanvas-nav .nav-close a {
    font-size: 0;
    padding: 0;
}

.main-view-detail-information.main-view-student-detail .title-title .edit i {
    font-size: 20px;
    margin-right: 6px;
}

.main-view-detail-information.main-view-student-detail .title-title .edit:hover {
    color: #FCB900;
}

.main-view-course .content-view-course {
    padding-top: 56px;
}

#chartdiv {
    width: 550px;
    height: 260px;
    display: inline-block;
}

header .menu-header ul li .content-bell {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 15px rgb(0 0 0 / 15%);
    position: absolute;
    width: 400px;
    right: 34px;
    z-index: 111;

    border-radius: 10px;
    top: 87px;
    display: none;
}

header .menu-header ul li .content-bell .content {
    padding: 25px;
    padding-bottom: 14px;
}

header .menu-header ul li .content-bell .title-2 {
    font: normal normal bold 24px/39px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

header .menu-header ul li .content-bell .tab ul {
    justify-content: start;
}

header .menu-header ul li .content-bell .tab ul li a {
    font: normal normal bold 14px/24px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    height: 31px;
    display: inline-block;
    border: 1px solid #fff;
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    line-height: 32px;
}

header .menu-header ul li .content-bell .tab ul li {
    margin-right: 10px
}

header .menu-header ul li .content-bell .tab ul li.active a {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    color: #FC8F00;
    border: 1px solid #F5F5F5;
}

header .menu-header ul li .content-bell .tab ul li:hover a {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    color: #FC8F00;
    border: 1px solid #F5F5F5;
}

header .menu-header ul li .content-bell .nav-content-bell .item .title-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin: 0;
}

header .menu-header ul li .content-bell .nav-content-bell .item .dot {
    width: 10px;
    height: 10px;
    background: #0077B6;
    display: inline-block;
    border-radius: 50%;
    float: right;
    margin-top: 7px;
}

header .menu-header ul li .content-bell .nav-content-bell .item .desc {
    font: normal normal 500 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    margin-bottom: 3px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

header .menu-header ul li .content-bell .nav-content-bell .item .desc.desc-full {
    display: inline-block;
}

header .menu-header ul li .content-bell .nav-content-bell .item .date {
    font: normal normal bold 14px/22px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    margin-bottom: 0
}

header .menu-header ul li .content-bell .nav-content-bell .item {
    border-top: 1px solid #ddd;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

header .menu-header ul li .content-bell .nav-content-bell .item .content-message {
    width: calc(100% - 25px);
}


header .menu-header ul li .content-bell .nav-content-bell .item:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

header .menu-header ul li .content-bell .nav-content-bell .item-3 .title-3 {
    color: #C1C1C1;
}

header .menu-header ul li .content-bell .nav-content-bell .item-3 .desc {
    color: #C1C1C1;
}

header .menu-header ul li .content-bell .nav-content-bell .item-3 .date {
    color: #C1C1C1;
}

header .menu-header ul li .content-bell .nav-content-bell .readmore {
    text-align: center;
    padding-bottom: 20px
}

header .menu-header ul li .content-bell .nav-content-bell .readmore a {
    font: normal normal bold 16px/26px Yu Gothic;

    color: #0077B6;
}

.arrow-top:before {
    position: absolute;
    top: -10px;
    left: 60%;
    margin-left: -10px;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.arrow {
    position: relative;
}

header .menu-header ul li.li-bell {
    position: inherit;
}

header.header-after-login .menu-header ul li a.click-bell {
    position: relative;
    cursor: pointer;
    min-width: 72px;
}

header.header-after-login .menu-header ul li a.click-bell span {
    position: absolute;
    display: inline-flex;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background: red;
    border-radius: 100%;
    top: -5px;
    right: 10px;
    color: white;
}

.scrollbar {
    overflow-y: overlay;
    max-height: 600px;
}

.scrollbar-message {
    overflow-y: overlay;
    max-height: calc(100vh - 330px);
}

.force-overflow {
    min-height: 450px;
}

/*
 *  STYLE 2
 */

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #DADDE2;
}

.menu-header-mobile {
    display: none !important;
    width: auto;
    top: 0;
    position: absolute;
    right: 0;
}

.menu-header-mobile .click-bell i {
    font-size: 20px;
    color: #000;
}

header .menu-header.menu-header-mobile {
    width: auto;
    top: 20px;
}

header.header-after-login .menu-header-mobile ul li a.click-bell span {
    top: -12px;
    right: 24px;
}

header .menu-header ul li .content-bell {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

header .menu-header ul li .content-bell.active {
    display: block;
}

.title-primary-setPass {
    font: normal normal normal 14px/16px Yu Gothic;
    letter-spacing: 0px;
    color: #e71313;
    margin-bottom: 2em;
}

.title-end-pass {
    margin-bottom: 2em;
}

.input-payment {
    width:'100%';
    height: 47px;
    border: 0;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    background-color: transparent;
}

.item-2-ticket {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 13px;
}

.note-ticket {
    color: #e71313;
    font: normal normal bold 17px/18px Yu Gothic;
    margin-top: 3em;
    margin-bottom: 3em;
    text-align: center;
}

.title-l-ticket {
    font: normal normal bold 16px/34px Yu Gothic;
    color: #363946;
    margin: 0;
}

.title-r-ticket {
    font: normal normal bold 16px/34px Yu Gothic;
    color: #363946;
    text-align: right;
    margin: 0;
}

.btn-add-code {
    width: 60px;
    font-size: 15px;
    text-align: center;
    background-color: #F5F3EF;
    padding: 0px;
    margin: 0px;
    border-width: 0;
    border-radius: 10px;
}

.btn-save-payment button {
    height: 56px;
    width: 230px;
    background: #FCB900 0% 0% no-repeat padding-box;
    border-radius: 28px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    border: 1px solid #FCB900;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    margin-top: 17px;
}

.btn-save-payment {
    text-align: center;
}

.title-payment-status {
    font: normal normal bold 40px/56px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    text-align: center;
}

/* #passwordConfirm {
    padding-right: 45px;
} */


.title-ticket {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding: 0;
}

#title-ticket-line2 {
    background: #363946 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font: normal normal bold 24px/36px Yu Gothic;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    padding: 14px 20px 9px 20px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.container-buy-courses {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.btn-buy-courses {
    width: 148px;
    height: 56px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #0077B6;
    border-radius: 28px;
    opacity: 1;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #0077B6;
    float: right;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.btn-buy-courses:hover {
    background: #0077B6;
    color: #fff;
}

/* input number hide arrow */
.tool-certificate-screen input::-webkit-outer-spin-button, .tool-certificate-screen input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* GA list CR */
.student-group-screen .content-top-student .select-search-toolcertificate-type {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.student-group-screen .content-top-student .select-search-toolcertificate-type .select-search-toolcertificate-active {
    color: #FFFFFF;
    background-color: #1A2C44;
}

.student-group-screen .content-top-student .select-search-toolcertificate-type label{
    flex: none;
    width: 112px;
    display: inline-block;
    margin-right: 20px;
    font: normal normal bold 16px / 26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
}

.student-group-screen .content-top-student .select-search-toolcertificate-type div{
    gap: 16px;
}

.student-group-screen .content-top-student .select-search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    border-radius: 4px;
    height: 48px;
    background-color: #FFFFFF;
    border: 1px solid #DADDE2;
    font: normal normal 500 16px / 26px Yu Gothic;
    cursor: pointer;
    transition: all 0.3s ease;
}

.student-group-screen .content-top-student .select-search-btn:hover {
    border: 1px solid #1A2C44;
    text-decoration: underline;
}

.flex-grow {
    flex-grow: 1;
}

.width-80 {
    width: 80%;
}