.height-desc-2 {
    height: 100px;
}

.hover-link:hover {
    cursor: pointer;
    color: #FCB900 !important;
    transition: all 0.7s;
}

.li-last .fa-user-group {
    font-size: 22px;
    margin-right: 12px;
    margin-left: -1.5px !important;
}

.nav-sub-menu .fa-user-group {
    font-size: 22px;
    margin-left: -16px !important;
}

.button-toggle-password i {
    font-size: 25px;
}

.warning-not-ticket {
    padding: 20px 20px 0px;
    text-align: center;
    color: red;
    font-weight: bold;
}

.red {
    color: red !important;
}

.page-body-buong {
    position: relative;
    min-height: 100vh;
}

.wrapper-container {
    padding-bottom: 227px;
}

.text-left {
    text-align: left !important;
}

.warning-expired {
    color: #F21313;
}

#Footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

#sendMessageScreenModal .modal-header .title-1 {
    display: inline-block;
}

#sendMessageScreenModal .modal-header .btn-close {
    float: right;
    margin-top: -5px;
}

.student-active,
.course-completed {
    color: #1292D7;
}

.student-confirm,
.course-attending {
    color: #FCA93B;
}

.delete-all-send {
    text-decoration: inherit;
    font: normal normal bold 14px/28px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    padding-right: 14px;
    margin-right: 14px;
    display: flex !important;
    align-items: center;
    position: relative;
}

.delete-all-send i {
    margin-right: 5px;
    font-size: 20px;
}

.delete-all-send::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 17px;
    background: #ddd;
    right: 0;
    top: 8px;
}

.name-ticket {
    text-decoration: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    letter-spacing: 0px;
    display: -webkit-box;
    word-break: break-all;
}

.add-attendance-code input, .add-attendance-code select {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 40px;
    padding-left: 15px;
}

.add-attendance-code .cancel {
    border-radius: 28px;
    height: 56px;
    width: 200px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    background: #DADDE2 0% 0% no-repeat padding-box;
    color: #363946;
    border: 1px solid #DADDE2;
    transition: all 0.7s ease;
}

.add-attendance-code .submit {
    margin-left: 20px;
    border-radius: 28px;
    height: 56px;
    width: 200px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    background: #FCB900 0% 0% no-repeat padding-box;
    color: #363946;
    border: 1px solid #DADDE2;
    transition: all 0.7s ease;
}

.add-attendance-code .field-required::after {
    position: relative;
    content: "*";
    color: red;
    top: -3px;
}

.add-attendance-code .calendar-custom input {
    height: 40px;
}

.add-attendance-code .calendar-custom .btn-icon-input {
    margin-top: -35px;
}

.cursor-pointer {
    cursor: pointer;
}

/* .logo-navbar {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.logo-navbar-img {
    height: 49px;
    width: 220px !important;
}

.logo-navbar-img:hover {
    cursor: pointer;
}

.change-password-screen .content-signin .item input {
    margin-top: 8px !important;
}

.text-red {
    color: #F21313 !important;
}

.no-data {
    text-align: center;
    margin: 80px auto;
}

.text-center {
    text-align: center !important;
}

.content-Attended-couse .text-link {
    color: #0077B6;
    text-decoration: none;
}

.content-Attended-couse .text-link:hover {
    color: #FCB900 !important;
}

.icon-lecture-new::before {
    content: 'NEW';
    background-color: red;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: bolder;
    color: white;
    border-radius: 100%;
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.course-card-name.icon-lecture-new::before {
    content: '-NEW-';
    color: red;
    background-color: transparent;
    padding: 2px 7px;
    font-size: 16px;
    font-weight: bolder;
    margin-right: 5px;
    position: relative;
    top: -2px;
}

.main-general-setting figure {
    display: block;
    margin: auto;
    max-width: 768px;
}

.main-general-setting figure figcaption {
    text-align: center;
    background: #F7F7F7;
    padding: 10px;
}

.main-general-setting figure img {
    width: 100%;
}

.main-general-setting .main-general-setting-content {
    overflow: hidden;
    padding-bottom: 50px;
}

.title-primary.icon-lecture-new::before {
    top: -12px;
}

.lecture-recommend.icon-lecture-new::before {
    font-size: 10px;
    padding: 4px 8px;
    top: -6px;
}

.link-message-detail {
    padding: 0px !important;
    width: 100%;
}

.list-account a {
    transition: none !important;
}

.color-link {
    color: #0d6efd;
}

.gr-course-wrapper {
    position: relative;

}

.gr-course-view {
    position: absolute;
    display: flex !important;
    top: 48px;
    right: 0;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 10;
}

.gr-course-list {
    border: 1px solid #DADDE2;
    flex: 1;
    background: #fff;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: scroll;
}

.gr-course-list p {
    cursor: pointer;
    border-top: 1px solid #DADDE2;
    padding: 10px 10px;
    margin-bottom: 0px;
}

.gr-course-list p:first-child {
    border-top: none;
}

.icon-btn {
    font-size: 18px;
}

.icon-btn-hidden {
    visibility: hidden;
}

.icon-btn-red {
    color: red;
}

.icon-btn-green {
    color: green;
}

.main-student-list .top-student-list .content-top-student .item-search-group {
    display: flex;
    align-items: center;
    /* gap: 16px; */
    margin-bottom: 25px;
}

.main-student-list .top-student-list .content-top-student .item-1 {
    padding-left: 30px;
    width: auto;
    display: flex;
    align-items: center;
    gap: 16px;
}

.main-student-list .top-student-list .content-top-student .item-1 .item-1-1 {
    width: auto;
    display: flex;
    align-items: baseline;
    gap: 16px;
}

.main-student-list .top-student-list .content-top-student .item-1 label {
    width: auto;
    float: none;
}

.main-student-list .top-student-list .content-top-student .item input[type=number],
.main-student-list .top-student-list .content-top-student .item .select-level {
    width: 100px;
    display: inline-block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px;
}

.main-student-list .top-student-list .content-top-student .item input[type=checkbox] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.mt-0 {
    margin-top: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.main-view-theme select {
    width: calc(100% - 132px);
    display: inline-block;
    float: right;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px;
}

.main-view-theme .last-filter {
    padding-left: 30px;
}

.main-view-theme .last-filter .list-check {
    padding-left: 0px !important;
}

.main-view-theme .last-filter .last-filter-label {
    padding-left: 0px !important;
}

header .menu-header ul li .content-bell .nav-content-bell .item .message-ga {
    font: normal normal bold 12px/20px Yu Gothic;
    letter-spacing: 0px;
    color: red;
    margin-bottom: 0;
}

header .menu-header ul li .content-bell .nav-content-bell .item-3 .message-ga {
    color: #C1C1C1;
}

.icon-verify-update-profile {
    display: inline-flex;
    position: absolute;
    align-items: center;
    height: 48px;
    padding-left: 10px;
}

.complete-video {
    font: normal normal bold 15px/28px Yu Gothic;
    padding: 0px 20px;
    text-align: center;
    /* color: #FCB900; */
    color: red;
}

.item-bod .container-input,
.item-bod .calendar {
    width: 333px !important;
    max-width: 333px !important;
}

.main-execute-completion .msg-error {
    font: normal normal bold 18px/30px Yu Gothic;
    padding: 0px 20px;
    color: red;
    padding-left: 0px !important;
    white-space: pre-line;
}

.item-theme .course-content {
    position: relative;
    height: 350px;
    overflow: hidden;
}

.item-theme .course-content-full {
    height: auto;
}

.item-theme .course-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to top, rgba(245, 246, 248, 1), rgba(245, 246, 248, 0.2));
}

.item-theme .btn-more {
    text-align: center;
    color: #FDB03B;
    font: normal normal bold 16px/20px Yu Gothic;
    cursor: pointer;
    padding: 10px 0px;
}

.item-theme .btn-more-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 320px);
    float: right;
}

.item-theme .btn-more:hover {
    text-decoration: underline;
}

.go-test {
    color: red;
    text-decoration: underline;
}

.go-test:hover {
    color: red;
    text-decoration: underline;
}

.attendance-history-screen .container {
    width: 100%;
}

.attendance-history-screen .top-student-list .content-top-student, .tool-certificate-screen .top-student-list .content-top-student {
    padding: 60px;
}

.attendance-history-screen .list-check {
    justify-content: flex-start !important;
}

.list-note {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.list-note .title-box {
    border-radius: 50% !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 13px !important;
    text-align: center !important;
    height: 37px;
    width: 66px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.list-note .content {
    line-height: 30px;
    /* font-weight: bold; */
}

.xanh {
    background-color: #00b050 !important;
}

.vang {
    background-color: #f7ee18 !important;
}

.cam {
    background-color: #fdc301 !important;
}

.tim {
    background-color: #d825de !important;
}

.rows-center {
    display: contents;
}

.fs-70 {
    font-size: 70px;
}

.fs-22 {
    font-size: 22px;
}

.fs-30 {
    font-size: 30px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.button-confirm-email { 
    background: #DADDE2 0% 0% no-repeat padding-box;
    border-radius: 28px;
    height: 56px;
    width: 200px;
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #363946;
    border: 1px solid #DADDE2;
    margin-top: 32px;
    transition: all 0.5s ease;
}

.button-confirm-email:hover {
    background-color: #FCB900;
    color: white;
}

.not-show-video {
    position: relative;
    background-color: rgb(0, 0, 0);
    /* min-height: 180px; */
    width: 100%;
    aspect-ratio: 16/9;
}

.not-show-video img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.not-show-video a {
    color: #FDB03B !important;
}

.main-view-course .content-view-course .content-course-right .item .img-course {
    width: 320px;
    display: inline-block;
    float: left;
    position: relative;
}

.main-view-course .content-view-course .content-course-right .item .img-course img{
    width: 100%;
    aspect-ratio: 16/9;
}

.main-view-course .content-view-course .content-course-right .item .img-course img {
    width: 100%;
}

.main-view-course .content-view-course .content-course-right .item .img-course {
    overflow: hidden;
}

.not-show-video p{
    color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    width: 100%;
}

.main-signin .content-signin .note span {
    text-decoration: underline;
}

.main-signin .content-signin .note span:hover {
    text-decoration-color: #FCB900;
}

.note-cs-3 {
    font: normal normal bold 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: #F21313;
    margin: 0;
}

.bank-logo img {
    width: 50px;
}

.dot-highlight::after {
    content: '';
    display: inline-flex;
    width: 13px;
    height: 13px;
    background: red;
    border-radius: 100%;
    margin-left: 10px;
}

.item-theme .title-course-sample {
    font: normal normal bold 14px/28px Yu Gothic;
    position: absolute;
    bottom: 0;
    align-self: center;
    box-sizing: border-box;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    color: #FFFFFF;
    text-align: center;
}

.price.price-ticket {
    font: normal normal bold 36px/40px Yu Gothic !important;
    padding: 15px 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.price.price-ticket > span:first-child {
    margin-right: 5px;
}

.note-price-ticket {
    font-size: 16px;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.delete-purchase {
    color: #F21313;
    border-color:#F21313;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.delete-purchase:hover {
    color: white;
    background-color: #F21313;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.export-invoice-purchase {
    color: #198754;
    border-color:#198754;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.export-invoice-purchase:hover {
    color: white;
    background-color: #198754;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.survey_btn {
    color: #fcb03b;
    border-color:#fcb03b;
    margin: 0;
}

.main-purchasse-history .item-purchasse .nav-item-purchasse .link-link button.survey_btn:hover {
    color: white;
    background-color: #fcb03b;
}

.section-fqa-right.section-fqa-right-title {
    flex-wrap: wrap;
}

.search-faqs-input {
    flex-wrap: wrap;
}

.search-faqs-input input {
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    min-width: 280px;
}

.content-main-view-course .container, .course-detail-screen .container, .student-group-screen .container, .home-page-group-admin .container {
    width: 100%;
    max-width: 1500px;
}

.content-view-course-bottom {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 24px;
}

.content-view-course-bottom .theme-tree-view {
    max-width: 260px;
    width: 100%;
    border: 1px solid #eeeeee;
    padding: 20px 10px 40px;
    border-radius: 12px;
}

.content-view-course-bottom .theme-tree-view .tree-all-node {
    padding: 4px 0px 4px 10px;
    background-color: #F5F6F8;
    border-radius: 3px;
    margin-bottom: 8px;
}

.content-view-course-bottom .theme-tree-view .tree-all-node:hover {
    background-color: #FAFAFA;
    color: #FCB900 !important;
    transition: all ease 0.3s;
    cursor: pointer;
}

.content-view-course-bottom .theme-tree-view .tree-node.theme-node {
    background-color: #F5F6F8;
    margin-top: 5px;
}

.content-view-course-bottom .view-course-right, .content-view-course-bottom .content-course-detail {
    padding-left: 0px !important;
    width: calc(100% - 300px);
    min-width: 700px;
}

.content-view-course-bottom .content-course-detail .cancel-attendance-btn-lecture-lst {
    width: 150px;
    height: 35px;
    background-color: #e8e8e8;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #e8e8e8;
    border-radius: 5px;
    color: rgba(2, 125, 180, 1);
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.content-view-course-bottom .title-search {
    margin-top: 0px !important;
    padding-bottom: 10px !important;
}

.sample-videos {
    margin-top: 80px;
    margin-bottom: -80px;
}

.video-preview .video-js {
    max-width: 768px !important;
    height: auto;
    aspect-ratio: 16/9;
}

.sample-videos .carousel-indicators {
    margin-bottom: -2.5rem;
}

.sample-videos .carousel-indicators [data-bs-target] {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #1A2C44;
    margin-left: 4px;
    margin-right: 4px;
}

.sample-videos .carousel-control-icon {
    font-size: 40px;
    color: #1A2C44;
    opacity: 0.5;
}

.sample-videos .carousel-control-next:hover .carousel-control-icon, .sample-videos .carousel-control-prev:hover .carousel-control-icon {
    opacity: 1;
}

.sample-videos .carousel-control-next, .sample-videos .carousel-control-prev {
    width: 20%;
}

.survey-content-screen .field-required::after {
    content: "*";
    color: red;
}

.sample-videos .title-primary {
    font: normal normal bold 32px/70px Yu Gothic;
}

.tool-certificate-screen .search-tool-certificate input[type='radio'] {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.tool-certificate-screen .search-tool-certificate input[type='submit']:disabled {
    background-color: #e9ecef !important;
    color: #899198 !important;
    cursor: not-allowed;
    transition: none;
}

.tool-certificate-screen .tool-certificate-warning {
    font: normal normal bold 16px / 26px Yu Gothic;
    letter-spacing: 0px;
    color: #fff;
    background: #FCB900;
    border: 1px solid #FCB900;
    padding: 9px 13px;
    text-align: center;
}

.tool-certificate-screen .tool-certificate-input-course {
    border: none;
    border-bottom: 1px solid #AAA;
}

.tool-certificate-screen .tool-certificate-button button {
    border-radius: 32px;
    width: 301px;
    height: 56px;
    font: normal normal bold 16px / 26px Yu Gothic;
    letter-spacing: 0px;
    color: #1A2C44;
    border: 0;
    min-width: 200px;
}

.tool-certificate-screen .tool-certificate-button button.btn-1 {
    background: #DADDE2;
}

.tool-certificate-screen .tool-certificate-button button.btn-1:hover {
    background: #fff;
    color: #000;
    border: 1px solid #DADDE2;
}

.tool-certificate-screen .tool-certificate-button button.btn-2 {
    background: #FCB900;
}

.tool-certificate-screen .tool-certificate-button button.btn-2:hover {
    background: #fff;
    color: #000;
    border: 1px solid #FCB900;
}

.student-group-screen table th:not(:nth-child(1), :nth-child(2)), .student-group-screen table td:not(:nth-child(1), :nth-child(2)) {
    padding: 9px 5px;
}

.course-list-screen .top-student-list .content-top-student .item-23-custom .search-checkbox {
    width: auto !important;
    margin-right: 20px;
    margin-top: 0px !important;
}

.course-list-screen .top-student-list .content-top-student .item-23-custom .search-checkbox:first-child {
    min-width: 120px;
}

.course-list-screen .top-student-list .content-top-student .item-23-custom .search-checkbox label {
    width: auto;
    min-width: 65px;
    margin: 0px !important;
}

.course-list-screen .btn-export-course-list {
    height: 44px;
    margin-right: 15px;
    font-weight: 500;
}

@media only screen and (max-width: 480px) {
    .course-list-screen .separation {
        display: none;
    }

    .content-view-course-bottom .content-course-detail {
        flex-direction: column;
    }
}


.payment-screen .content-Pay-ment .title-r .calendar-custom .container-calender .calendar {
    transform: translate(-45%, 0);
}

header {
    position: sticky;
    top: 0px;
    z-index: 200;
    background-color: #FFFFFF;
}

.header-after-login-for-std .header-general {
    padding: 16px 34px;
}

.header-after-login-for-std .header-general.menu-header-for-std {
    padding: 0px 34px;
}

.header-after-login-for-std .menu-header-for-std {
    background-color: #1a2c44;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 80px;
}

.header-after-login-for-std .menu-header-for-std li a {
    font: normal normal 500 16px/26px Yu Gothic;
    letter-spacing: 0px;
    color: white;
    text-decoration: none;
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 10px 0px;
}

.header-after-login-for-std .menu-header-for-std li a:hover {
    cursor: pointer;
    color: #FCB900;
}

/* css card ticket */
.ticket-card.item {
    background: white;
    min-height: auto;
    border: none !important;
}

.ticket-card.item:hover {
    background-color: #FCB900;
}

.ticket-card.item:hover h3, .ticket-card.item:hover .nav-item {
    border-color: #FCB900;
}

.ticket-card.item h3 {
    margin-bottom: 0px;
    border: 2px solid #F5F5F5;
    border-bottom: none;
}

.ticket-card.item .nav-item {
    background-color: white;
    padding-top: 13px;
    min-height: 333px;
    border: 2px solid #F5F5F5;
    border-top: none;
    border-radius: 0px 0px 24px 24px;
    transition: all 0.7s ease;
}

.ticket-card-container .ticket-info-detail {
    min-height: 100px;
}

.ticket-card-container .desc-1 {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ticket-card-container .desc-1.desc-1-1 {
    margin-bottom: 5px !important;
    position: relative;
}

.ticket-card-container .desc-1.desc-1-1 .ticket-course-info {
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 1px);
    transform: translate(0%, -50%);
}

.ticket-card-container .desc-1.desc-1-1 span, .ticket-card-container .desc-1 span.desc-1-2 {
    color: #1A2C44 !important;
}

.ticket-card-container .desc-1 span.desc-1-2 {
    flex: none;
    position: relative;
}

.ticket-card-container .desc-1 i {
    font-size: 11px;
    position: relative;
    top: -2px;
}

.ticket-card-container .desc-1 .desc-label-1-1 {
    font-weight: 500 !important;
    color: #1A2C44 !important;
    display: inline-block;
    padding: 0px 5px;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .join-course.join-course-btn:disabled, .btn-buy-courses:disabled {
    color: #6d757d !important;
    border-color: #6d757d !important;
}

.main-view-course .content-view-course .content-course-right .item .nav-img .join-course.join-course-btn:disabled:hover, .btn-buy-courses:disabled {
    background-color: #fff !important;
}

@media only screen and (max-width: 1400px) {
    .attendance-history-screen .table-table table {
        display: block;
        overflow: scroll;
        width: 100%;
    }

	.attendance-history-screen .table-table table tr:first-child td:first-child {
		min-width: 52px !important;
	}

	.attendance-history-screen .table-table table tr:first-child td:nth-child(2),
    .attendance-history-screen .table-table table tr:first-child td:nth-child(7),
    .attendance-history-screen .table-table table tr:first-child td:nth-child(9) {
		min-width: 200px !important;
	}

    .attendance-history-screen .table-table table tr:first-child td:nth-child(3),
    .attendance-history-screen .table-table table tr:first-child td:nth-child(4),
    .attendance-history-screen .table-table table tr:first-child td:nth-child(6),
    .attendance-history-screen .table-table table tr:first-child td:nth-child(8) {
		min-width: 150px !important;
	}

    .attendance-history-screen .table-table table tr:first-child td:nth-child(5) {
		min-width: 120px !important;
	}

    .tool-certificate-screen .content-tool-certificate .table-table table.table-tool-certificate-new {
        display: block;
        overflow: scroll;
        width: 100%;
    }

    .tool-certificate-screen .content-tool-certificate .table-popup table.table-tool-certificate-new {
        display: block;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 884px) {
    .tool-certificate-screen .content-tool-certificate .table-popup table.table-tool-certificate-new {
        display: block !important;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 560px) {
    .tool-certificate-screen .content-tool-certificate .table-popup table.table-tool-certificate-update {
        display: block !important;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    header .menu-header-for-std {
		display: none !important;
	}

    .header-after-login-for-std .header-general {
        padding-left: 0;
		padding: 8px 0;
    }

    .wrapper-container {
        padding-bottom: 233px;
    }

    .main-student-list .top-student-list .content-top-student {
        padding: 20px;
    }

    .tool-certificate-screen .top-student-list .content-top-student {
        padding: 40px;
    }

    .attendance-history .custom-item-22 {
        padding: 0px 20px !important;
    }

    .sample-videos {
        margin-bottom: -30px;
    }

    .attendance-history-screen .container {
        max-width: 95%;
    }
}

@media only screen and (max-width: 1224px) {
    .main-general-setting .container {
        max-width: 100%;
    }

    .content-view-course-bottom {
        flex-direction: column;
        gap: 24px;
    }
    
    .content-view-course-bottom .theme-tree-view {
        max-width: 100%;
    }
    
    .content-view-course-bottom .view-course-right, .content-view-course-bottom .content-course-detail {
        width: 100%;
        min-width: 0px;
    }

    .sample-videos .carousel-control-next, .sample-videos .carousel-control-prev {
        width: 5%;
    }
}

@media only screen and (max-width: 1200px) {
    .tool-certificate-screen .content-tool-certificate .table-table table.table-tool-certificate-update {
        display: block;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .gr-course-view {
        padding-right: 0px;
    }

    .attendance-history .content-student-list .col-md-5,
    .attendance-history .content-student-list .col-md-7 {
        width: 100%;
    }

    .attendance-history .content-student-list .filter-list {
        width: 100%;
    }

    .attendance-history .content-student-list .filter-list .btn-export1 {
        margin-bottom: 10px;
    }

    .attendance-history .content-top-student .item-22 {
        display: flex !important;
        flex-direction: column;
    }

    .attendance-history .list-check {
        display: flex;
        flex-wrap: nowrap !important;
        margin-top: 10px !important;
    }

    .attendance-history-screen .custom-item-22 .list-check {
        display: flex;
        flex-wrap: wrap !important;
        margin-top: 10px !important;
    }

    .attendance-history .list-check li {
        display: flex !important;
        margin-bottom: 10px;
        align-items: center;
    }

    .attendance-history-screen .search-status .list-check li {
        margin-bottom: 0px !important;
    }

    /* .attendance-history .list-check li input {
        width: 25px !important;
        height: 33px !important;
    } */

    .attendance-history .list-check li label {
        margin-right: 0 !important;
    }

    .attendance-history .top-student-list .content-top-student {
        padding: 20px !important;
    }

    .attendance-history-screen .top-student-list .content-top-student .custom-item-22 .calendar-custom {
        width: 100%;
        margin-top: 15px;
    }

    .attendance-history-screen .top-student-list .content-top-student .custom-item-22 .list-check {
        width: 100%;
    }

    .attendance-history-screen .top-student-list .content-top-student .custom-item-22 input {
        margin-bottom: 0px;
    }

    .main-view-theme .top-student-list .content-top-student .row .col-md-6 {
        width: 96%;
    }

    .main-view-theme .top-student-list .content-top-student .item label {
        margin-right: 20px !important;
    }

    .main-view-theme .last-filter {
        width: 100%;
    }

    .main-view-theme .item-23 .list-check {
        justify-content: flex-start !important;
    }

    /* .main-view-theme .item-23 .list-check li:first-child label {
        margin-right: 35px !important;
    } */

    .item-theme .chart-theme .title-intro {
        text-align: left !important;
        padding-left: 0px !important;
    }

    .item-theme .chart-theme .col-md-6 {
        width: 100%;
    }

    .item-theme .chart-theme .chart-area1-item {
        float: left;
    }

    .main-view-theme .item-24 li:last-child {
        margin-top: 10px;
    }

    .main-view-theme .clock-video ul {
        display: block !important;
        overflow: hidden;
    }

    .main-view-theme .clock-video li {
        float: left;
    }

    .main-view-theme .clock-video ul .ic-last {
        width: 100%;
    }

    .section-fqa-right.section-fqa-right-title .title-primary {
        width: 100%;
    }

    .attendance-history-screen .search-status {
        align-items: start !important;
    }

    .sample-videos .video-preview .video-js {
        width: 100% !important;
    }

    .sample-videos .carousel-control-next, .sample-videos .carousel-control-prev {
        display: none;
    }

    .tool-certificate-screen .sidebar-tool-certificate, .tool-certificate-screen .container-tool-certificate {
        width: 100% !important;
    }

    .tool-certificate-screen .content-tool-certificate .table-table table.table-tool-certificate-update {
        display: block;
    }

    .main-student-list .content-student-list .filter-list {
        display: inline-block;
        width: 100%;
        text-align: center;
        float: none;
    }

    .lst-student-table {
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 768px) {
    .main-view-theme .top-student-list .content-top-student .row .col-md-6:not(.ignore-css) {
        width: 100%;
    }

    .main-view-theme .top-student-list .content-top-student .item:not(.ignore-css) input[type=text],
    .main-view-theme select:not(.ignore-css) {
        width: calc(100% - 50px);
    }

    .main-view-theme .top-student-list .content-top-student {
        padding: 20px !important;
    }

    .wrapper-container {
        padding-bottom: 279px;
    }

    .wrapper-container {
        padding-bottom: 380px;
    }

    .modal-content {
        min-width: 0px !important;
    }

    .tool-certificate-screen .tool-certificate-button button {
        width: 150px;
    }
}

@media only screen and (max-width: 767px) { 
    .tool-certificate-screen .search-tool-certificate .submit-search {
        margin: 25px auto 0px;
    }
}

@media only screen and (max-width: 758px) {
    .tool-certificate-screen .content-tool-certificate .table-table table.table-tool-certificate-update {
        display: block;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 533px) {
    .tool-certificate-screen .content-tool-certificate .table-table table.table-tool-certificate-update {
        display: block;
        overflow: scroll;
        width: 100%;
    }
}

@media only screen and (max-width: 736px) {
    .attendance-history .list-check li input {
        width: 35px !important;
        height: 33px !important;
    }

    .container-student-group-list .calendar-custom .container-input input {
        height: 40px;
    }

    .container-student-group-list .item-search-group input[type="number"],
    .container-student-group-list .item-search-group select {
        height: 40px !important;
    }

    .gr-course-list p {
        padding: 8px 10px;
    }

    .main-view-theme .chart-area1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-view-theme .c1 {
        margin-bottom: 30px;
    }

    .main-view-theme .item-23:not(.ignore-css) li input,
    .main-view-theme .item-24:not(.ignore-css) li input {
        width: 37px !important;
        height: 33px !important;
        display: block !important;
    }

    .main-view-theme .item-23 li label,
    .main-view-theme .item-24 li label {
        margin-top: 3px !important;
    }

    .main-view-theme .item-23:not(.ignore-css) ul,
    .main-view-theme .item-24:not(.ignore-css) ul {
        display: grid !important;
        grid-template-columns: repeat(2, 50%);
    }

    .main-view-theme .item-23 li,
    .main-view-theme .item-24 li {
        width: 100% !important;
        display: flex !important;
    }

    .main-view-theme .item-23:not(.ignore-css) li {
        margin-right: 0px !important;
    }

    .main-view-theme .item-23 .list-check li:last-child input {
        margin-left: -6px !important;
        width: 35px !important;
    }

    .main-view-theme .item-22:not(.ignore-css) select {
        height: 40px;
    }

    .main-Pay-ment .content-content {
        width: 100%;
    }

    .sample-videos {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .sample-videos .title-primary {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
    }

    .attendance-history-screen .container {
        max-width: 98%;
    }
}

@media only screen and (max-width: 680px) {

    .main-general-setting figure,
    .main-general-setting figure img {
        width: 100% !important;
    }

    .main-student-list .top-student-list .content-top-student .item-2 {
        width: 315px;
        justify-content: space-between;
    }

    .gr-course-view {
        top: 70px;
        padding-right: 0px;
    }

    .gr-course-view label {
        display: none !important;
    }

    .main-view-theme .top-student-list .content-top-student .item:not(.ignore-css) input[type=text],
    .main-view-theme select:not(.ignore-css) {
        width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 576px) {
    /* .attendance-history .list-check {
        display: grid !important;
        grid-template-columns: repeat(2, 50%);
    }

    .attendance-history .list-check .li-0 {
        width: 45% !important;
    }

    .attendance-history .list-check .li-1 {
        width: 55% !important;
    } */

    .main-view-theme .item-24 li:last-child {
        margin-top: 0px;
    }

    .add-attendance-code .cancel {
        width: 150px;
    }

    .add-attendance-code .submit {
        width: 150px;
    }

    header.header-after-login .menu-header ul li a.click-bell {
        position: relative;
        cursor: pointer;
        min-width: 50px;
    }

    header.header-after-login .menu-header ul li a.click-bell span {
        position: absolute;
        display: inline-flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: red;
        border-radius: 100%;
        top: -10px;
        right: 5px;
        color: white;
    }

    .Banner-Home .content-banner {
        height: 200px;
        margin-top: -60px;
    }

    .Banner-Home .content-banner .title-1 {
        font-size: 20px;
        line-height: 26px;
    }

    .Banner-Home .content-banner .desc {
        font-size: 14px;
        margin-top: 16px;
    }

    .wapper-button-payment {
        flex-direction: column-reverse;
    }

    .button-payment {
        width: 100%;
    }

    .button-payment button{
        width: 100% !important;
    }

    .attendance-history .custom-item-22 {
        padding: 0px 10px !important;
    }

    .title-payment-status { 
        font-size: 36px;
    }

    .course-list-screen {
        padding-bottom: 50px;
    }

    .col-12-max-576 {
        width: 100%;
    }

    .main-student-list .content-student-list .filter-list ul {
        gap: 10px !important;
    }
}

@media only screen and (max-width: 500px) {

    .button-puchase-item {
        flex-direction: column;
        gap: 0px;
    }

    .button-puchase-item button {
        width: 100% !important;
    }

    .attendance-history-screen .submit-search {
        width: 160px !important;
        margin: 15px auto 0px;
    }

    .tool-certificate-screen .tool-certificate-button {
        flex-direction: column-reverse;
        gap: 20px;
    }
    
    .tool-certificate-screen .tool-certificate-button button {
        width: 280px;
    }

    .course-list-screen .top-student-list .content-top-student .item {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .course-list-screen .top-student-list .content-top-student .item input[type='text'], 
    .course-list-screen .top-student-list .content-top-student .item select {
        width: calc(100% - 20px);
    }

    .course-list-screen .top-student-list .content-top-student .item .list-check {
        width: 100% !important;
    }
}

@media only screen and (max-width: 450px) {
    .main-student-list .calendar {
        width: 100% !important;
        min-width: 100% !important;
    }

    .main-student-list .rdrMonth {
        width: 100% !important;
    }

    .bod-view {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .item-bod .container-input,
    .item-bod .calendar {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }

    .footer-button {
        display: flex;
        flex-direction: column-reverse;
    }

    .add-attendance-code .cancel {
        margin-top: 15px;
        width: 100%;
    }

    .add-attendance-code.parent-company-filter-modal .cancel {
        margin-top: 0;
    }

    .add-attendance-code .submit {
        margin-left: 0px;
        width: 100%;
    }

    .Banner-Home .content-banner {
        height: 180px;
        margin-top: -40px;
        padding: 20px;
    }

    .Banner-Home .content-banner .title-1 {
        font-size: 17px;
        line-height: 24px;
    }

    .Banner-Home .content-banner .desc {
        font-size: 13px;
        margin-top: 16px;
    }

    .main-Attended-couse .content-top-student .item-22 {
        padding-left: 5px;
    }

    .main-Attended-couse .content-top-student .item-22:not(.ignore-css) label {
        margin-bottom: 10px;
    }

    .main-Attended-couse .content-top-student ul:not(.ignore-css) input {
        margin-bottom: 10px;
    }

    /* .main-student-list .content-student-list .filter-list ul {
        display: block !important;
    } */

    .main-Attended-couse .content-student-list .filter-list a {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    .main-student-list .content-student-list .table-table {
        width: 100%;
    }

    .main-student-list .content-student-list .table-table table {
        display: block;
        width: 100%;
        overflow: scroll;
    }

    .main-student-list .top-student-list .content-top-student .item-1 {
        padding-right: 0;
        width: 100%;
        justify-content: space-between;
    }

    .main-student-list .top-student-list .content-top-student .item-1 .item-1-1 {
        gap: 10px;
    }

    .update-info-name {
        width: 100%;
    }

    .title-payment-status { 
        font-size: 26px;
    }

    .payment-screen .content-Pay-ment .title-r .calendar-custom .container-calender .calendar {
        width: 300px;
        transform: translate(-58%, 0);
    }

    .payment-screen .content-Pay-ment .title-r .calendar-custom .container-calender .calendar .rdrMonth {
        width: auto;
    }

    .lst-student-table {
        margin-top: 15px !important;
    }
}

@media (min-width: 451px) and (max-width: 750px) {

    #sendMessageScreenModal .modal-dialog .content-right .link-link {
        display: block !important;
    }

    #sendMessageScreenModal .modal-dialog .content-right .link-link button {
        width: auto !important;
        min-width: 180px !important;
    }

    #sendMessageScreenModal .modal-dialog .content-right .link-link .link-2 {
        margin-left: 20px !important;
    }
}

@media only screen and (min-width: 576px) {
    .container-student-group-list {
        max-width: 95%;
        width: 1400px;
    }
}

@media (max-width: 450px),
(max-width: 880px) {
    #sendMessageScreenModal .modal-dialog .content-right .link-link {
        display: flex;
        flex-direction: column-reverse;
    }

    #sendMessageScreenModal .modal-dialog .content-right .link-link button {
        width: 100%;
    }

    #sendMessageScreenModal .modal-dialog .content-right .link-link .link-2 {
        margin-left: 0;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 350px) {
    .logo-navbar-img {
        width: 200px !important;
    }

    .Banner-Home .content-banner {
        height: 150px;
        margin-top: -40px;
        padding: 15px;
    }

    .Banner-Home .content-banner .title-1 {
        font-size: 15px;
        line-height: 20px;
    }

    .Banner-Home .content-banner .desc {
        font-size: 12px;
        margin-top: 15px;
        line-height: 22px;
    }
}

.test-result-history {
    width: 100% !important;
}

.test-result-history .test-result-info {
    font-size: 24px;
    background: #F5F6F8 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 28px 20px;
    margin-top: 35px;
    width: 100% !important;
}

.test-result-history .test-result-info select {
    min-width: 300px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DADDE2;
    border-radius: 4px;
    height: 48px;
    padding-left: 15px;
}

#modal-message-popup .message-popup-container .message-popup-tittle {
    flex-wrap: wrap;
    gap: 5px;
}

#modal-message-popup .message-popup-container .message-popup-content .text-start .font-bold{
    color: #1A2C44;
    font-size: 20px;
}

#modal-message-popup .message-popup-container .message-popup-content .text-start .font-light{
    color: #5c5c5c;
}

.cancel-attendance-btn-for-history {
    border: 1px solid #abd7ee;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
}