.course-card-wrapper {
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
    .course-card-container {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 30px;
        .img-course {
            position: relative;
            width: 320px;
            flex: none;
            .title-course-sample {
                font: normal normal bold 14px/28px Yu Gothic;
                position: absolute;
                bottom: 0;
                align-self: center;
                box-sizing: border-box;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                margin: 0;
                color: #FFFFFF;
                text-align: center;
            }
    
            .not-show-video {
                aspect-ratio: 16 / 9;
                background-color: #000;
                position: relative;
                width: 100%;
            }
            @media (max-width: 1024px) {
                width: 100%;
            } 
        }
        
        .course-content {
            flex-grow: 1;
            .title-category {
                font-weight: 700;
                font-style: normal;
                font-size: 14px;
                color: #027DB4;
                margin-bottom: 10px;
            }
            .title-3 {
                font-weight: 700;
                font-style: normal;
                font-size: 22px;
                color: #000000;
                display: flex;
                align-items: center;
                gap: 5px;
                @media (max-width: 640px) {
                    flex-direction: column;
                    align-items: start;
                } 
                .item-course-status {
                    font-size: 13px;
                    padding: 6px 8px;
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                    gap: 5px;
                    flex: none;
                    flex-wrap: wrap;
                    margin-bottom: auto;
                    @media (max-width: 640px) {
                        margin-left: 0;
                    } 
                    .dot {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                    }
                }
            }
            .course-tag-container {
                text-decoration: none;
                gap: 10px;
                flex-wrap: wrap;
                font-weight: 600;
                .list-note {
                    .title-box {
                        height: 30px;
                    }
                }
                .course-type-tag {
                    font-size: 13px;
                    color: #D9001B;
                    background-color: #ffebeb;
                    border: 1px solid #ffebeb;
                    padding: 1px 8px;
                }
                .course-specified-tag {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-size: 13px;
                    color: #D9001B;
                    border: 1px solid #D9001B;
                    padding: 1px 8px;
                }
                .item-course-status {
                    font-size: 13px;
                    padding: 2px 8px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
    
                    .dot {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                    }
                }
            }
    
            .clock-video {
                margin-top: 50px;
                ul {
                    display: flex !important;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    padding: 0;
                    font-size: 13px;
                    color: #7F7F7F;
                }
                @media (max-width: 1024px) {
                    margin-top: 10px;
                } 
            }
            @media (max-width: 1024px) {
                width: 100%;
            } 
        }
    
        .course-action-btn {
            font-weight: 600;
            button {
                font-weight: 600;
                width: 150px;
                height: 35px;
                background-color: rgba(2, 125, 180, 1);
                box-sizing: border-box;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(2, 125, 180, 1);
                border-radius: 5px;
                color: #FFFFFF;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
    
                @media (max-width: 1024px) {
                    width: 50%;
                } 
            }

            button:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
            }

            button.is-cancel-attendance {
                @media (max-width: 1024px) {
                    width: 33.33% !important;
                }
                @media (max-width: 640px) {
                    width: 100% !important;
                } 
            }

            .cancel-attendance-btn {
                width: 150px;
                height: 35px;
                background-color: #e8e8e8;
                box-sizing: border-box;
                border-width: 1px;
                border-style: solid;
                border-color: #e8e8e8;
                border-radius: 5px;
                color: rgba(2, 125, 180, 1);
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease;

                @media (max-width: 1024px) {
                    margin-top: 0;
                    width: 33.33% !important;
                }
                @media (max-width: 640px) {
                    width: 100% !important;
                } 
            }

            .cancel-attendance-btn:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
            }
    
            .show-course-detail-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                width: 150px;
                height: 35px;
                border-width: 1px;
                border-style: solid;
                border-color: rgba(2, 125, 180, 1);
                background-color: #FFFFFF;
                color:rgba(2, 125, 180, 1);
                border-radius: 5px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
    
                .expand-icon {
                    transition: transform 0.3s ease;
                }
    
                @media (max-width: 1024px) {
                    margin-top: 0;
                    width: 50%;
                }
            }

            .show-course-detail-btn:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                border: none;
            }

            .show-course-detail-btn.is-cancel-attendance {
                @media (max-width: 1024px) {
                    width: 33.33% !important;
                }
                @media (max-width: 640px) {
                    width: 100% !important;
                } 
            }
    
            .show-course-detail-btn.toggled {
                .expand-icon {
                    transform: rotate(-180deg);
                }
            }

            .show-course-detail-btn.dont-login {
                margin-top: 0;
            }

            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 100%;
                margin-top: 0;
            } 
        }

        .course-action-btn.is-cancel-attendance {
            @media (max-width: 640px) {
                display: flex;
                flex-direction: column;
                gap: 10px;
            } 
        }
    
        @media (max-width: 1024px) {
            flex-direction: column;
            margin-top: 20px;
        } 
    }
    
    .course-card-container.course-detail {
        align-items: flex-start;
        .img-course {
            @media (max-width: 1024px) {
                display: none;
            } 
        }
    
        .course-card-detail {
            flex-grow: 1;
            border-top: 1px dotted #AAAAAA;
            @media (max-width: 1024px) {
                margin-top: 30px;
            } 
            .sidebar {
                border-bottom: 1px solid #f2f2f2;
                .sidebar-item {
                    font-weight: 600;
                    padding: 10px 30px;
                    color: #7F7F7F;
                    font-size: 14px;
                    cursor: pointer;

                    @media (max-width: 640px) {
                        padding: 10px;
                        width: 33.33%;
                        text-align: center;
                    } 
                }
    
                .sidebar-item.active {
                    color: rgb(2, 125, 180);
                    border-bottom: 2px solid rgb(2, 125, 180);
                }
            }
    
            .course-detail-container {
                .content-list-container {
                    padding: 15px 4px;
                    .content-list-item {
                        background-color: #f5f6f8;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 20px;
                        border-radius: 4px;
                        margin-top: 5px;
                        gap: 20px;
                        .title {
                            font-weight: 700;
                            font-style: normal;
                            color: #000000;
                            font-size: 14px;
                        }
                        .info {
                            display: flex;
                            align-items: center;
                            gap: 50px;
                            .video-length {
                                font-size: 12px;
                                color: #000000;
                            }
                            .lecture-status {
                                font-weight: 600;
                                font-size: 12px;
                                .dot {
                                    height: 4px;
                                    width: 4px;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }

                    .content-list-item:hover {
                        .title {
                            color: #0077B6;
                            text-decoration: underline;
                        }
                    }
    
                    .content-view-more {
                        background-color: #d5d7d9;
                        display: flex;
                        align-items: center;
                        padding: 5px 20px;
                        border-radius: 4px;
                        margin-top: 5px;
                        gap: 5px;
                        span {
                            color: #7F7F7F;
                            font-weight: 400;
                            font-style: normal;
                            font-size: 13px;
                            letter-spacing: normal;
                            cursor: pointer;
                        }
    
                        span:hover {
                            text-decoration: underline;
                        }
    
                        i {
                            color: #7F7F7F;
                        }
                    }
                }
                .progress-result-container {
                    .title-intro {
                        color: #333;
                        margin-bottom: 0px;
                        font-size: 21px;
                        font: normal normal bold 21px/30px Yu Gothic;
                    }
                }
                .course-info-container {
                    .title-intro {
                        color: #333;
                        margin-bottom: 0px;
                        font-size: 21px;
                        font: normal normal bold 21px/30px Yu Gothic;
                    }
                }
            }
    
            @media (max-width: 1024px) {
                width: 100%;
            } 
        }
    }
    @media (max-width: 1024px) {
        padding-bottom: 20px;
    } 
}