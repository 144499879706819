.information-screen {
    .title {
        font-family: 'Arial Bold', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 32px;
        color: #1A2C44;
        margin-top: 30px;
    }
    .list-container {
        margin-top: 50px;
        .filter {
            border-bottom: 1px solid #d7d7d7;
            @media (max-width: 1280px) {
                flex-direction: column-reverse;
                align-items: start !important;
            }
            .filter-sidebar {
                @media (max-width: 1280px) {
                    width: 100%;
                }
                .filter-item {
                    text-align: center;
                    padding: 12px 20px;
                    font-size: 14px;
                    color: #555555;
                    min-width: 100px;
                    cursor: pointer;
                    @media (max-width: 1280px) {
                        width: 20%;
                        min-width: 0;
                        padding: 16px 4px;
                    }
                }
                .filter-item-active {
                    border-bottom: 2px solid #cf041b;
                    font-weight: bold;
                    color: #000;
                }
            }

            .filter-select {
                @media (max-width: 480px) {
                    flex-direction: column;
                }
                .filter-select-item {
                    min-width: 175px;
                    height: 30px;
                    padding: 2px 2px 2px 10px;
                    font-family: 'Arial', sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    letter-spacing: normal;
                    color: #000000;
                    text-align: left;
                    text-transform: none;
                    background-color: transparent;
                    border-color: #f2f2f2;
                    border-radius: 4px;
                }
            }
        }
        .content {
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            column-gap: 5px;
            row-gap: 10px;
            @media (max-width: 992px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (max-width: 600px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
            @media (max-width: 480px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            .grid-item {
                border: 1px solid #f2f2f2;
                border-radius: 4px;
                padding: 8px;
                .title {
                    min-height: 42px;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 14px;
                    letter-spacing: normal;
                    color: #333333;
                }
                .icon-bg {
                    padding: 5px;
                    border-radius: 2px;
                }
                .public-date {
                    color: #AAAAAA;
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }
}

.information-screen-detail {
    .information-type {
        margin-top: 80px;
        .information-type-icon {
            padding: 6px;
            border-radius: 4px;
            span {
                font-size: 14px;
                line-height: 14px;
            }
        }
        .information-type-public-date {
            border-radius: 4px;
            border: 1px solid #f2f2f2;
            padding: 2px 12px;
            color: #aaaaaa;
            font-size: 14px;
        }
    }

    .title {
        margin-top: 10px;
        font-weight: 700;
        font-style: normal;
        font-size: 22px;
        color: #000000;
        line-height: 30px;
    }

    .content {
        margin-top: 10px;
    }

    .back-to-list-btn {
        margin-left: auto;
        width: 150px;
        height: 35px;
        background-color: #e8e8e8;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #e8e8e8;
        border-radius: 5px;
        color: rgb(2, 125, 180);
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .informtation-docs {
        margin-top: 30px;
        padding-top: 10px;
        border-top: 1px dotted #aaaaaa;
        .informtation-docs-title {
            font-weight: 700;
            font-style: normal;
            font-size: 18px;
            color: #000000;
        }
        .informtation-docs-content {
            padding: 10px 20px;
            .informtation-docs-item {
                color: #348ece;
                a {
                    color: #348ece;
                }
            }
        }
    }

    .relative-information {
        margin-top: 50px;
        background-color: rgba(244, 244, 244, 0.6039215686274509);
        border: none;
        border-radius: 5px;
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
        color: #000000;
        padding: 30px;
        @media (max-width: 768px) {
            padding: 10px;
        }
        .relative-information-content {
            padding: 10px 20px;
            @media (max-width: 768px) {
                padding: 5px 10px;
            }
            .relative-information-item {
                font-weight: 500;
                padding: 10px 0;
                border-bottom: 1px dotted #aaaaaa;
                font-size: 14px;
                .relative-information-item-calendar {
                    color: #aaaaaa;
                    font-size: 14px;
                }
            }
        }
    }
}