.notify {
    // border: 3px solid #1a2c44;
    border-radius: 10px;
    margin: 50px auto 0px;
    padding: 15px;

    .title {
        display: flex;
        align-items: center;

        .loudspeaker {
            width: 50px;
            margin-right: 10px;
        }

        span {
            font: normal normal bold 26px/40px Yu Gothic;
            letter-spacing: 0px;
            color: #1A2C44;
            margin: 0;
        }
    }

    .list-notify {
        padding: 5px 30px;
        height: 180px;
        overflow: overlay;

        .notify-content {
            padding: 15px 12px 15px 0px;
            // border-bottom: 1px solid #00000014;
            font-weight: bolder;
            display: flex;
            flex-wrap: nowrap;

            .notify-date {
                color: #5c5c5c;
                margin-right: 20px;
            }

            &:hover {
                cursor: pointer;

                .notify-title {
                    color: #FCB900;
                    transition: all ease 0.3s;
                }
            }
        }

        .border-bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.0784313725);
        }
    }
}

.banner-mobile {
    display: none !important;
}

.home-statistical {
    .content-student-list {
        .title-primary {
            font-size: 30px;
        }
    }

    .select-group {
        width: 240px;
        display: inline-block;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DADDE2;
        border-radius: 4px;
        height: 48px;
        padding-left: 15px
    }

    .filter-children-group {
        min-width: 142px;
        height: 44px;
        background: #FCB900 0% 0% no-repeat padding-box;
        border-radius: 28px;
        font: normal normal bold 16px / 26px Yu Gothic;
        letter-spacing: 0px;
        color: #1A2C44;
        border: 0;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.filter-modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: hsla(0, 0%, 50%, .692) !important;
    z-index: 7000;

    .filter-modal-content {
        position: absolute;
        inset: 50% auto auto 50%;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        max-height: 90%;
        max-width: 90%;
        overflow: visible !important;
        padding: 0 !important;
    }
}

@media only screen and (max-width: 1280px) { 

    .home-statistical {
        .content-student-list {
            .title-primary {
                font-size: 26px;
            }
        }
    }
}

@media only screen and (max-width: 736px) { 

    .banner-web {
        display: none !important;
    }

    .banner-mobile {
        display: inline-block !important;
    }

    .home-statistical {
        .content-student-list {
            .title-primary {
                font-size: 24px;
            }
        }
    }
}

@media only screen and (max-width: 768px) { 

    .notify {
        width: 90%;
    }
}