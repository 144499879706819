.carousel-container {
    margin-left: 0;
    .slide-content {
        height: 40px;
        padding: 0 20px;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 600px) {
            gap: 5px;
        }        
        .public-date {
            font-size: 12px;
            color: #7F7F7F;
        }

        .title {
            font-size: 14px;
            font-weight: normal;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* Giới hạn hiển thị đúng 1 dòng */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            @media (max-width: 600px) {
                font-size: 14px;
            } 
        }
          
        .title:hover {
            font-weight: bold;
        }

        button {
            border: none;
            background-color: transparent;
        }
    }
}  