.tree-view-custom {
    list-style: none;
    margin: 0;
    padding: 0;
	font-size: 15px;

    .tree-node {
		list-style: none;
		margin: 0;
		padding: 4px 0px;
		border-radius: 3px;
		margin-top: 2px;
		align-items: start;

		i {
			margin-top: 4px;
		}

		input {
			margin-top: 5px;
		}

		&:hover {
			background-color: #FAFAFA !important;
			transition: all ease 0.3s;
		}

		.tree-name {
			white-space: pre-wrap;
			font-weight: 700;
		}

		.tree-name.has-click, .tree-icon {
			&:hover {
				color: #FCB900 !important;
				transition: all ease 0.3s;
				cursor: pointer;
			}
		}

		.tree-name.tree-name-highlight {
			color: #FCB900 !important;
		}
	}

	.tree-node-group {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.tree-branch-wrapper {
		outline: none;
	}

	.tree-node__leaf {
		outline: none;
	}

	.tree-node__branch {
		display: block;
	}
}